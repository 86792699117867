<template>

    <div 
            class="loadingSpinner isVisible" 
            v-if="loading"
            :aria-label="loadingText"
    >
        
        <div class="innerSpinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>

        <div class="loadingText" v-if="showLoadingText && loadingText.length">

            <div class="loadingTextWrap" v-html="loadingText"></div>
            
        </div> <!-- .loadingText -->
        
    </div>

</template>

<script>
export default {
    name: 'loadingSpinner',
    props: {
        loading: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        loadingText: {
            type: [String, Boolean],
            default (rawProps) {
                return 'Loading';
            }
        },
        showLoadingText: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';

    .loadingSpinner {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 300ms, visibility 300ms;
        width: 100px;
        height: 100px;
        display: inline-block;
        //overflow: hidden;
        background: none;

        &.isVisible {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transition: opacity 300ms, visibility 300ms;
        }
        
        .loadingText {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 200px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
        }

        .loadingTextWrap {
            font-size: 14px;
            line-height: 1.2em;
            font-weight: 600;
            color: @text_color;
            text-align: center;
        }
    }

    @keyframes innerSpinner {
        0% { transform: translate(6px,40px) scale(0); }
        25% { transform: translate(6px,40px) scale(0); }
        50% { transform: translate(6px,40px) scale(1); }
        75% { transform: translate(40px,40px) scale(1); }
        100% { transform: translate(74px,40px) scale(1); }
    }
    @keyframes innerSpinner-r {
        0% { transform: translate(74px,40px) scale(1); }
        100% { transform: translate(74px,40px) scale(0); }
    }
    @keyframes innerSpinner-c {
        0% { background: @primary_color }
        25% { background: @primary_color }
        50% { background: @primary_color }
        75% { background: @primary_color }
        100% { background: @primary_color }
    }
    .innerSpinner {

        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */

        div {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            transform: translate(40px,40px) scale(1);
            background: @primary_color;
            animation: innerSpinner 2.7027027027027026s infinite cubic-bezier(0,0.5,0.5,1);

            &:nth-child(1) {
                background: @primary_color;
                transform: translate(74px,40px) scale(1);
                animation: innerSpinner-r 0.6756756756756757s infinite cubic-bezier(0,0.5,0.5,1), innerSpinner-c 2.7027027027027026s infinite step-start;
            }

            &:nth-child(2) {
                animation-delay: -0.6756756756756757s;
                background: @primary_color;
            }

            &:nth-child(3) {
                animation-delay: -1.3513513513513513s;
                background: @primary_color;
            }

            &:nth-child(4) {
                animation-delay: -2.027027027027027s;
                background: @primary_color;
            }

            &:nth-child(5) {
                animation-delay: -2.7027027027027026s;
                background: @primary_color;
            }
        }

        div { box-sizing: content-box; }
    }

</style>
