<template>

    <div class="weightCalculator">


        <div class="weightUnitType">

            <div class="row">


                <div class="optionButtons radioButtons">

                    <div class="labelButton">

                        <input type="radio"
                               :id="'question_' + question.id + 'weight_type_metric'"
                               value="Metric"
                               v-model="type"
                               :checked="type == 'Metric'"
                        />

                        <label
                                :for="'question_' + question.id + 'weight_type_metric'"
                        >
                            Metric
                        </label>

                    </div> <!-- .labelButton -->

                    <div class="labelButton">

                        <input type="radio"
                               name="weight[type]"
                               :id="'question_' + question.id + 'weight_type_imperial'"
                               value="Imperial"
                               v-model="type"
                               :checked="type == 'Imperial'"
                        />

                        <label
                                :for="'question_' + question.id + 'weight_type_imperial'"
                        >
                            Imperial
                        </label>

                    </div> <!-- .labelButton -->

                </div> <!-- .optionButtons -->

            </div> <!-- .row -->

        </div>


        <div class="weightCalculatorFields">

            <div class="weightFields weightMetricFields" v-show="isType('Metric')">
                

                <fieldset class="weightFieldset">

                    <legend>Weight</legend>

                    <div class="row">

                        <div class="inputWrap">

                            <label
                                    :for="'question_' + question.id + '_weight_kg'"
                            >kg</label>

                            <input
                                    :id="'question_' + question.id + '_weight_kg'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="800"
                                    v-model="weightKg"
                                    @input="update('weightKg')"
                                    @change="weightKgChanged = true;"
                            >

                        </div> <!-- .inputWrap -->

                    </div> <!-- .row -->

                </fieldset>

            </div> <!-- .bmiMetricFields -->


            <div class="weightFields weightMetricFields" v-show="isType('Imperial')">
                

                <fieldset class="weightFieldset">

                    <legend>Weight</legend>

                    <div class="row">

                        <div class="inputWrap">

                            <label
                                    :for="'question_' + question.id + '_weight_stone'"
                            >stone</label>

                            <input
                                    :id="'question_' + question.id + '_weight_stone'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="100"
                                    v-model="weightStone"
                                    @input="update('weightStone')"
                            >

                        </div> <!-- .inputWrap -->

                        <div class="inputWrap">

                            <label
                                    :for="'question_' + question.id + '_weight_pounds'"
                            >pounds</label>

                            <input
                                    :id="'question_' + question.id + '_weight_pounds'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="13"
                                    v-model="weightPounds"
                            >

                        </div> <!-- .inputWrap -->

                    </div> <!-- .row -->

                </fieldset>

            </div> <!-- .weightMetricFields -->


        </div>


        <input type="hidden"
               :name="questionName"
               :id="'id_' + question.id"
               v-model="weightCalculation"
               @blur="updateQuestion(question)"
               @input="updateQuestion(question, true)"
               autocomplete="off"
        >

    </div> <!-- .bmiCalculator -->

</template>

<script>

import {props,computed, methods} from "../mixins/fields";

export default {
    name: 'weightField',
    mixins: [props,computed, methods],
    props: {
        setType: {
            type: [String], //'Metric' || 'Imperial'
            default (rawProps) {
                return 'Metric';
            }
        },
        setWeightKg: {
            type: [Number],
            default (rawProps) {
                return 0;
            }
        },
        setWeightStone: {
            type: [Number],
            default (rawProps) {
                return 0;
            }
        },
        setWeightPounds: {
            type: [Number],
            default (rawProps) {
                return 0;
            }
        }
    },
    data() {
        return {
            type: 'Metric', //'Metric' || 'Imperial'
            weightKg: 0,
            weightStone: 0,
            weightPounds: 0,
            currentWeightValue: 0,

            changed: {
                weightKg: false,
                weightStone: false
            }
        }
    },
    created() {
        this.type = this.setType;
        this.weightKg = this.setWeightKg;
        this.weightStone = this.setWeightStone;
        this.weightPounds = this.setWeightPounds;
    },
    computed: {

        weightCalculation() {

            let weight = 0;

            if(this.isType('Metric')) {
                weight = parseInt(this.weightKg);
            }else{

                if(parseInt(this.weightStone) > 0) {
                    let totalPounds = (parseInt(this.weightStone) * 14) + parseInt(this.weightPounds);
                    weight = (totalPounds * 0.453592);
                }
            }

            
            return parseFloat(weight.toFixed(2));

        }

    },
    watch: {
        weightCalculation(value, oldValue) {

            let val = parseFloat(value);

            if(!isNaN(val)) {
                this.currentWeightValue = val;
            }else{
                this.currentweightValue = 0;
            }
        }
    },
    methods: {

        isType(unitType) {
            return this.type.toLowerCase() == unitType.toLowerCase();
        },

        update(type) {

            const _this = this;

            this.changed[type] = true;


            if( this.updateAllowed() ) {


                this.$nextTick(() => {

                    _this.question.answer = _this.currentWeightValue;
                    _this.updateQuestion(_this.question);

                });

            }

        },

        updateAllowed() {

            if(this.type == 'Metric') {

                if(this.changed.weightKg) {
                    return true;
                }

            }else if(this.type == 'Imperial') {

                if(this.changed.weightStone) {
                    return true;
                }

            }

            return false;

        },

        updateQuestion: _.debounce(function(question, inputEvent) {

            if(typeof inputEvent == 'undefined' || this.allowInputEvent) {
                question = this.validate(question);
                this.$emit('update-question', question);
            }

        }, 200),

        validate(question) {

            let canProceed = true;

            if (!question.answer > 0 && question.is_required) {
                canProceed = false;
            }

            question.can_proceed = canProceed;

            return question;

        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    
    .weightCalculator {
        
        width: 100%;
        position: relative;
    
    
        .weightCalculatorFields {
    
            padding: 12px 0 0;
            margin: 24px 0 0;
            border-top: 1px solid @border_color;
            
            @media @mobile {
                padding: 20px 0 0;
            }
        }
    
        .weightCalculation {
    
            padding: 20px;
            margin: 24px 0 0;
            border: 1.5px solid @border_color_dark;
            .rems(14);
            line-height: 1.2em;
            font-weight: 600;
            border-radius: 5px;
    
            span {
                font-weight: normal;
            }
    
        }
    
        .weightFields {
    
            .row {
                display: flex;
                gap: 0 16px;
    
                @media @mobile {
                    flex-wrap: wrap;
                    gap: 0;
                }
    
                &:before, &:after {
                    display: none;
                }
            }
    
            .inputWrap {
    
                position: relative;
                float: none;
                clear: both;
                padding: 0;
                flex: 1 1 100%;
    
                @media @mobile {
                    flex: 0 0 100%;
                    margin-bottom: 12px;
    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
    
                input {
                    position: relative;
                    z-index: 1;
                    float: none !important;
                    clear: both !important;
                    padding-right: 64px !important;
                    -moz-appearance: textfield;
                    border-radius: 59px;
    
                    /* Chrome, Safari, Edge, Opera */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
    
                }
    
                label {
                    background: @primary_color;
                    color: #fff;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px;
                    width: 64px;
                    height: ~"calc(100% - 2px)";
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    bottom: 1px;
                    z-index: 2;
                    border-radius: 0 59px 59px 0;
                    margin: 0;
    
                }
            }
        }
    
        fieldset {
    
            legend {
    
                display: block;
                .rems(12);
                line-height: 1.2em;
                font-weight: 600;
                color: @text_color;
                margin: 0 0 8px;
    
            }
    
        }
    
        .row {
    
            .radioButtons {
    
                .labelButton {
    
                    label {
                        border-radius: 59px !important;
                    }
    
                }
    
            }
    
        }
        
    }


</style>
