<template>
    <section
            class="deliveryCountdown"
            v-if="cutoffTime > 0"
    >

        <div class="deliveryCountdown__wrapper">
            
            <countdown :time="cutoffTime" class="deliveryCountdown__timer">
                <template slot-scope="props">
                 
                    <div class="deliveryCountdown__message">
                        For next day delivery, order in the next
                    </div> <!-- .deliveryCountdown__message -->
                    
                    <div class="deliveryCountdown__time" v-if="props.days < 1">
                        
    
                        <template v-if="props.hours > 0">
    
                            <div class="deliveryCountdown__block">
    
                                <div 
                                        class="deliveryCountdown__value" 
                                        v-html="formatValues(props.hours)"
                                ></div> <!-- .deliveryCountdown__value -->
    
                                <div class="deliveryCountdown__units">
                                    hrs
                                </div> <!-- .deliveryCountdown__units -->
    
                            </div> <!-- .deliveryCountdown__block -->
                           
    
                        </template>
    
                        <template>
    
                            <div class="deliveryCountdown__block">
    
                                <div 
                                        class="deliveryCountdown__value" 
                                        v-html="formatValues(props.minutes)"
                                ></div> <!-- .deliveryCountdown__value -->
    
                                <div class="deliveryCountdown__units">
                                    mins
                                </div> <!-- .deliveryCountdown__units -->
    
                            </div> <!-- .deliveryCountdown__block -->
                          
    
                        </template>
    
                        <template>
    
                            <div class="deliveryCountdown__block">
    
                                <div 
                                        class="deliveryCountdown__value" 
                                        v-html="formatValues(props.seconds)"
                                ></div> <!-- .deliveryCountdown__value -->
    
                                <div class="deliveryCountdown__units">
                                    secs
                                </div> <!-- .deliveryCountdown__units -->
    
                            </div> <!-- .deliveryCountdown__block -->
    
                        </template>
                        
                    </div> <!-- .deliveryCountdown__time -->
                    
                
                </template>
            </countdown>

        </div> <!-- .deliveryCountdown__wrapper -->

    </section> <!-- .deliveryCountdown -->

</template>

<script>

import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
    name: 'deliveryCountdown',
    components: {
        countdown: VueCountdown
    },
    props: {
        cutoff: {
            type: [String, Number, Boolean],
            default() {
                return false;
            }
        }
    },
    computed: {
        cutoffTime() {
            
            if(this.cutoff) {
                
                let time = parseInt(this.cutoff);
                
                if(time > 0) {
                    return time * 1000;
                }
                
            }
            return 0;
        }
    },
    methods: {
        formatValues(val) {
            
            const value = parseInt(val);
            
            if(value < 10) {
                return '0' + value;
            }
            
            return value;
            
        }
    }

}

</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .deliveryCountdown {
        
        --countdownColor: #23887F;
        
        &__wrapper {
            border-radius: 70px;
            border: 2px solid var(--countdownColor);
            background: #FFF;
            padding: 18px 29px;
            color: var(--countdownColor);
        }
        
        &__timer {
            display: flex;
            gap: 14px;
            .rems(17);
            font-weight: 600;
            line-height: normal;
            align-items: center;
        }
        
        &__time {
            display: flex;
            gap: 4px;
            text-align: center;
            margin-left: auto;
        }
        
        &__block {
            min-width: 40px;
        }
        
        &__value {
            .rems(28);
            font-weight: 700;
        }
        
        &__units {
            .rems(16);
            font-weight: 600;
        }
        
    }

</style>
