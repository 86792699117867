Vue.component('account-verification', {
    props: [
        'emailIn',
        'contactNoIn',
    ],
    data: function () {
        return {
            delivery_method: 'email',
            contact_no: '',
            email: '',
            errors: [],
            messages: [],
        };
    },
    created: function () {
        this.email = this.emailIn;
        this.contact_no = this.contactNoIn;
    },
    methods: {
        requestCode: function () {
            this.errors = [];
            this.messages = [];
            this.$http.post('/members/verify.php', {
                'action': 'send_code',
                'method': this.delivery_method,
                'email': this.email,
                'contact_no': this.contact_no
            }).then(function (response) {
                this.messages = response.data.messages;
                this.errors = response.data.errors;
            }).catch(function (response) {
                this.errors = response.data.errors;
            });
        }
    }
});
