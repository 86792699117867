<template>
    <div class="mNumberInput">
        <input :type="inputType"
               :class="['mNumberInput__input', inputClass]"
               :id="id"
               :name="name"
               :min="min"
               :max="max"
               :autocomplete="autocomplete"
               :maxlength="maxlength"
               :readonly="readonly"
               :disabled="disabled"
               @input="updateValue"
               @blur.prevent="checkNotEmpty"
               @keydown.up.prevent="incrementCounter"
               @keydown.down.prevent="decrementCounter"
               @keydown.space.prevent
               ref="mNumberInput"
               v-model="counter"
        >
        <div class="mNumberInput__buttons">
            <button class="mNumberInput__button mNumberInput__button--add" @click.prevent="incrementCounter">
                <slot name="add">+</slot>
            </button>
            <button class="mNumberInput__button mNumberInput__button--subtract" @click.prevent="decrementCounter">
                <slot name="subtract">-</slot>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'mtcNumberInput',
    props: {
        inputClass: String,
        id: String,
        name: String,
        inputType: {
            type: [String],
            default: 'number'
        },
        value: {
            type: [Number, String],
            default: 0
        },
        min: {
            type: Number,
            default: 1
        },
        max: {
            type: Number,
            default: Infinity
        },
        increment: {
            type: Number,
            default: 1
        },
        autocomplete: String,
        readonly: String,
        disabled: String,
        maxlength: Number
    },

    data () {
        return {
            counter: Number(this.value)
        }
    },

    watch: {
        counter (value, oldValue) {
            
            // if counter is more than max value then set it to the max value
            if (value > this.max) {
                this.counter = this.max
            }
            // if counter is less than min value then set it to the min value
            if (value < this.min) {
                this.counter = this.min
            }
           
            // emit change event for use by other components
            this.$emit('input', value, oldValue)
        }
    },

    methods: {
        incrementCounter () {
            this.counter = this.counter + this.increment
        },

        decrementCounter () {
            this.counter = this.counter - this.increment
        },

        checkNotEmpty () {
            // if counter is an empty string then set it to min value
            if (this.counter === '') {
                this.counter = this.min
            }
            
        },

        updateValue () {
            
            let value = false

            if (this.$refs.mNumberInput.value !== '') {
                value = this.counter = Number(this.$refs.mNumberInput.value)
            }

            return value
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    
    .mNumberInput {
        position: relative;
        padding: 0 22%;
    }
    
    .mNumberInput input {
        position: relative;
        z-index: 1;
        width: 100% !important;
        height: 44px !important;
        min-height: 44px !important;
        min-width: 44px !important;
        padding: 8px !important;
        text-align: center !important;
    }

    .mNumberInput input::-webkit-outer-spin-button,
    .mNumberInput input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .mNumberInput input[type=number] {
        -moz-appearance: textfield;
    }
    
    .mNumberInput__buttons {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        z-index: 2;
        pointer-events: none;
    }
    
    .mNumberInput__button {
        position: absolute;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
        padding: 0;
        background: none;
        border: 0;
        .rems(18);
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: auto;
        height: 100%;
        width: 22%;
        color: fade(#BABABA, 50%);
        transition: color 200ms linear;
        
        &:hover, &:focus, &:active {
            color: @highlight_color;
            transition: color 200ms linear;
        }
    }
    
    .mNumberInput__button.mNumberInput__button--add {
        left: auto;
        right: 0;
        text-align: right;
    }

    .mNumberInput__button.mNumberInput__button--subtract {
        left: 0;
        right: auto;
        text-align: left;
    }

    
    
</style>
