<template>

    <input type="number"
           :name="questionName"
           :id="'id_' + question.id"
           v-model="question.answer"
           @blur="updateQuestion(question)"
           @input="updateQuestion(question, true)"
           autocomplete="off"
           class="assessmentNumberField"
    >
    
</template>

<script>

import _ from 'lodash';
import {props, computed, methods} from "../mixins/fields";

export default {
    name: "numberField",
    mixins: [props, computed, methods],
    props: {
        allowInputEvent: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    methods: {

        updateQuestion: _.debounce(function(question, inputEvent) {

            if(typeof inputEvent == 'undefined' || this.allowInputEvent) {
                question = this.validate(question);
                this.$emit('update-question', question);
            }

        }, 200),

        validate(question) {

            let canProceed = true,
                    number = parseInt(question.answer);

            if(isNaN(number) && question.is_required) {
                canProceed = false;
            }
            
            question.can_proceed = canProceed;

            return question;

        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .assessmentNumberField {
        border-radius: 59px !important;
    }

</style>
