$(document).ready(function() {

    'use strict';


    if($('.categoryTabsSection').length) {

        const $tabsContainer = $('.categoryTabsSection'),
            $tabsList = $tabsContainer.find('.categoryTabs ul'),
            $sections = $tabsContainer.find('.categoryListWrap');

        $tabsList.find('.tabButton').on('click', function(){

            let $btn = $(this),
                target = $btn.attr('data-target'),
                $target = $(target);

            if($target.length) {

                $tabsList.find('.tabButton').removeClass('active');
                $btn.addClass('active');
                $sections.removeClass('activeList');
                $target.addClass('activeList');

            }

        });


    }




});
