$('document').ready(function () {
    'use strict';

    if ($('.otherServices .wrapper ul li').length) {

        slickIt('.otherServices .wrapper ul', window.breakpoints.tablet + 20, {
            dots: false,
            infinite: true,
            arrows: true,
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [{
                breakpoint: (window.breakpoints.tablet + 20),
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }, {
                breakpoint: (window.breakpoints.mobile + 1),
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }]
        });
    }

}); // document ready

$(window).on('resize', debouncer(function (e) {
    'use strict';

    if ($('.otherServices .wrapper ul li').length) {

        slickIt('.otherServices .wrapper ul', window.breakpoints.tablet + 20, {
            dots: false,
            infinite: true,
            arrows: true,
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [{
                breakpoint: (window.breakpoints.tablet + 20),
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }, {
                breakpoint: (window.breakpoints.mobile + 1),
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }]
        });
    }

})); // debounce

