<template>
    
    <div class="notifyGP">
        
        <input type="hidden" v-model="question.answer" :name="questionName">

        <gp-surgery-search 
                search-url="/shop/ajax/doctor_surgery_search.php"
                search-placeholder="Search doctor surgery name or address"
                :user-surgery="surgery"
                :instance-id="question.id"
                @address-updated="updateAnswer"
        ></gp-surgery-search>
        
    </div> 
    
</template>

<script>

import { mapGetters } from 'vuex';
import _ from 'lodash';
import { props, computed, methods } from "../mixins/fields";

export default {
    name: 'notifyGp',
    mixins: [props, computed, methods],
    computed: {
        
        ...mapGetters({
            surgery: 'getUserSurgery'
        })
        
    },
    methods: {
        
        updateAnswer(address) {
     
            if(address) {
                this.question.answer = address;
            }else{
                this.question.answer = '';
            }
            
            this.updateQuestion(this.question);
        },

        updateQuestion: _.debounce(function(question) {

            question = this.validate(question);
            this.$emit('update-question', question);

        }, 200),

        validate(question) {

            let canProceed = true;

            if(typeof question.answer == 'object') {
                
                if((!question.answer?.address_1?.length && !question.answer?.city && question.answer?.postcode) && question.is_required) {
                    canProceed = false
                }
                
            }else {
                if (!question.answer?.length && question.is_required) {
                    canProceed = false
                }
            }

            question.can_proceed = canProceed;

            return question;

        }

        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    

</style>
