$(document).ready(function () {

    'use strict';

    if($('.js_mobileNav').length) {
        showHideMenus('.js_mobileNav .sidebarTitle', window.breakpoints.flyout);
    }

    if($('ul.faqs').length) {
        
        $('ul.faqs').each(function () {

            var $list = $(this);

            if ($list.hasClass('faqClosed')) {
                $list.find('li .answer').hide();
            } else {
                $list.find('li .answer:not(:first)').hide();
                $list.find('li:first h4').addClass('open');
            }

        });

        $('html').on('click', 'ul.faqs>li>h4', function(e) {
            var $btn = $(this);
            $btn.toggleClass('open').next('.answer').stop(true,true).slideToggle();
        });
        
    }


}); // document ready

$(window).on('resize', debouncer(function (e) {
    'use strict';


    if($('.js_mobileNav').length) {
        showHideMenus('.js_mobileNav .sidebarTitle', window.breakpoints.flyout);
    }

})); // debounce
