<template>

    <div 
            class="productListWrap" 
            :class="[loading ? 'isLoading' : '', ...parentClass]"
            :id="parentId"
    >

        <template v-if="slider">


            <Splide
                    :options="sliderOptions"
                    ref="productSlider"
                    :class="[products.length < 4 ? 'hideArrows' : '']"
            >
                
                <SplideSlide
                        v-for="product in products"
                        :key="'img' + product.id"
                >

                    <product-card
                            :product="product"
                            :in-stock="product.in_stock"
                            :from-price="product.from_price"
                            :product-url="product.url"
                            :product-image="product.image"
                            :basket-button-data="product.basket_button_data"
                            :category="product.main_category"
                            :preview-only="cardPreviews"
                            :slideshow="false"
                            :minimal="minimal"
                            :class="[...cardClass]"
                            :use-trustpilot="true"
                    ></product-card>

                </SplideSlide>
    
            </Splide>

            
        </template>
            
        <template v-else>

            <ul
                    class="productList"
                    :class="[...listClass]"
                    v-if="hasProducts(products)"
            >

                <template v-for="product in products">

                    <li>

                        <product-card
                                :product="product"
                                :in-stock="product.in_stock"
                                :from-price="product.from_price"
                                :product-url="product.url"
                                :product-image="product.image"
                                :basket-button-data="product.basket_button_data"
                                :category="product.main_category"
                                :preview-only="cardPreviews"
                                :slideshow="false"
                                :minimal="minimal"
                                :class="[...cardClass]"
                                :use-trustpilot="true"
                        ></product-card>

                    </li>

                </template>

            </ul>
            
        </template>
        

        <div class="noProducts" v-else>
            <h4 class="noProductsTitle">No products found</h4>
        </div> <!-- .noProducts -->

    </div> <!-- .browseProducts -->


</template>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    name: "ProductList",
    components: {
        Splide, 
        SplideSlide
    },
    props: {
        slider: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        parentId: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        parentClass: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        listClass: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        cardClass: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        products: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        loading: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        minimal: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        cardPreviews: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
    },
    data() {
        return {
            sliderOptions: {
                gap: 40,
                rewind: false,
                pagination: false,
                arrows: true,
                fixedWidth: 385,
                perMove: 1,
                mediaQuery: 'max',
                breakpoints : {
                    640: {
                        fixedWidth: 282,
                        perMove: 1,
                        gap: 16
                    },
                }
            },
        }
    },
    methods: {

        hasProducts(products) {

            if(typeof products != 'undefined') {
                return products.length;
            }

            return false;

        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .productList {
        .itemsList();
    }
    
    .noProducts {
        display: flex;
        justify-content: center;
        padding: 48px 0;

        .noProductsTitle {
            text-align: center;
            margin: 0;
            .rems(18);
            line-height: 24px;
        }
    }
    
    .productListWrap {
        
        .splide__track {
            overflow: visible;
        }

        .splide__arrows {
            
            .splide__arrow{
                
                width: 50px;
                height: 50px;
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transition: opacity 200ms linear, visibility 200ms linear;
                
                &[disabled] {
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity 200ms linear, visibility 200ms linear;
                }
                
                &:after {
                    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none"><path d="M26.1698 0.601329C12.1986 0.601329 0.839354 11.9606 0.839354 25.9317C0.839355 39.9029 12.1986 51.2621 26.1698 51.2621C40.1409 51.2621 51.5002 39.9029 51.5002 25.9317C51.5002 11.9606 40.1409 0.601328 26.1698 0.601329ZM29.4236 38.3575L25.4782 34.4121L31.1837 28.7067L11.6649 28.7046L11.6649 23.1596L31.1837 23.1596L25.4782 17.4542L29.4236 13.5088L41.7949 25.9324L29.4236 38.3575Z" fill="%23165F59"/></svg>');
                    width: 100%;
                    height: 100%;
                    transform: translate(-50%, -50%) rotate(0deg);
                    background: transparent;
                }

                &.splide__arrow--next{
                    right: -5vw;
                    
                    @media all and (max-width: 1400px) {
                        right: -16px;
                    }
                }
                
                &.splide__arrow--prev {

                    left: -5vw;

                    @media all and (max-width: 1400px) {
                        left: -16px;
                    }
                    
                    &:after {
                        transform: translate(-50%, -50%) rotate(-180deg);
                        
                    }
                    
                }
                
                &:hover, &:active, &:focus-visible {
                    opacity: 0.8;
                    transition: opacity 200ms linear, visibility 200ms linear;
                }

                svg {
                    display: none;
                }
                
            }
            
        }
        
        .splide {
            
            &.hideArrows {
                .splide__arrows {
                    
                    @media all and (min-width: 1201px) {
                        display: none;
                    }
                }
            }
            
        }
        
    }

</style>
