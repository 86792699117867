$(document).ready(function() {

    'use strict';

    /*
     * Delivery Timer
    */
    if ($('.deliveryTimer .timer').length) {

        var cut_off = $('.deliveryTimer').data('cutoff');

        $('.deliveryTimer .timer').countdown({
            labels: [' years ', ' months ', ' weeks ', ' days ', 'hrs ', 'mins ', 'secs'],
            labels1: [' year ', ' month ', ' week ', ' day ', 'hr ', 'min ', 'sec'],
            until: '+' + cut_off,
            format: 'HMS'
        });

    }

});
