<template>
    
    <section class="datePickerWrapper">
        
        <input type="text"
            :id="id"
            :name="name"
            class="datePicker"
            :class="inputClass"
            :placeholder="placeholder ? placeholder : ''"
            @input="updateDate()"
        />
        <input 
                style="display: none" 
                type="text" 
                class="datePickerVueAlt" 
                :id="id_alt" 
        />
        
    </section>
    
</template>

<script>

import moment from 'moment';

export default {

    name: 'datepicker',

    props: {
        inputClass: {
            type: String,
            default: ''
        },
        id: String,
        name: String,
        placeholder: String,
        value: String,
        disabledDates: {
            type: Array,
            default: () => { return []; }
        },
        options: {
            type: Object,
        },
    },

    computed: {

        id_alt() {
            return this.id + '_alt';
        }

    },
    
    data() {
        return {
            date: ''
        }
    },

    methods: {

        updateDate() {

            if(this.date.length) {
                this.$emit('input', moment(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            }else{
                this.$emit('input', '');
            }
        },

        defaults() {
            // define some defaults
            const _this = this;
            
            let defaults = {
                showButtonPanel: true,
                dateFormat: 'dd/mm/yy',
                changeYear: true,
                yearRange: '1910:2030',
                onSelect: (date) => { 
                    
                    _this.date = date;
                    _this.updateDate() 
                
                }, // on select run updateDate function
                beforeShowDay: function (date) {
                    let string = moment(date).format('D/M/Y');
                    // if disabledDates is defined then indexOf it else indexOf empty string
                    return [ (this.disabledDates || '').indexOf(string) == -1 ]
                },
                altField: '#' + this.id_alt,
                altFormat: 'yy-mm-dd',
                //minDate: "+7d",
                //maxDate: "-1d",
            };

            return Object.assign({}, defaults, this.options);
        },

        getDatepickerElement() {
            return this.$el.getElementsByClassName('datePicker')[0];
        },

        updateComponentValue() {
            if (this.value) {
                this.getDatepickerElement().value = moment(this.value).format('DD/MM/YYYY');
            }
        }

    },

    mounted() {
        let vm = this,
            $el = vm.getDatepickerElement();

        this.updateComponentValue();
        $($el).datepicker(this.defaults());
    },

    beforeDestroy() {
        $(this.getDatepickerElement())
            .datepicker('hide')
            .datepicker('destroy');
    },

    watch: {
        value() {
            let vm = this,
                $el = vm.getDatepickerElement();

            this.updateComponentValue();
            $($el).datepicker('refresh');
        }
    },

}

</script>
