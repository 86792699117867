$(document).ready(function () {

    'use strict';
    
    if($('.hs__wrapper_mobile').length && $(window).width() <= 640) {
        window.sideScroller('.hs__wrapper_mobile', '.hs');
    }

});


window.sideScroller = (wrapper, innerWrapper) => {
    'use strict';

    var instance = $(wrapper);

    if (instance.length) {

        $.each(instance, function (key, value) {

            var box = $(instance[key]).find(innerWrapper),
                x = 0,
                mx = 0,
                maxScrollWidth = box[0].scrollWidth - (box[0].clientWidth / 2) - (box.width() / 2),
                arrows = $(instance[key]).find('.arrow'),
                prevArrow = arrows.filter('.arrow-prev'),
                nextArrow = arrows.filter('.arrow-next');

            $(arrows).on('click', function (e) {

                e.preventDefault();
            
                
                var offset = 0;

                if ($(this).hasClass("arrow-next")) {
                    x = (box.find('li').eq(0).width() + box.scrollLeft()) + offset;
                    
                    box.animate({
                        scrollLeft: x
                    }, 1200);
                } else {
                    x = (box.find('li').eq(0).width() - box.scrollLeft()) + offset;

                    box.animate({
                        scrollLeft: -x
                    }, 1200);
                }
            });

            $(box).on({
                mousemove: function (e) {
                    var mx2 = e.pageX - this.offsetLeft;
                    if (mx) this.scrollLeft = this.sx + mx - mx2;
                },
                mousedown: function (e) {
                    this.sx = this.scrollLeft;
                    mx = e.pageX - this.offsetLeft;
                },
                scroll: function () {
                    if (box.scrollLeft() > maxScrollWidth - 40) {
                        // disable next button when right end has reached
                        nextArrow.addClass('disabled');
                    } else if (box.scrollLeft() < 10) {
                        // disable prev button when left end has reached
                        prevArrow.addClass('disabled')
                    } else {
                        // both are enabled
                        nextArrow.removeClass('disabled');
                        prevArrow.removeClass('disabled');
                    }
                }
            });

            $(document).on("mouseup", function () {
                mx = 0;
            });

        });

    }

}
