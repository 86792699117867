<template>

    <div class="warningWrap" v-show="warning.visible || customError.length">


        <div
                class="warningBox error"
                v-html="customError"
                v-if="customError"
        ></div>
        
        <div 
                :class="cssClass"
                class="warningBox"
                v-html="warning.question_text"
                v-else
        ></div>

        
        
    </div>
    
</template>

<script>

export default {
    name: "warningWrap",
    components: {
       
    },
    props: {
        questionId: {
            type: [Number, String],
            default (rawProps) {
                return false;
            }
        },
        customError: {
            type: [String],
            default (rawProps) {
                return '';
            }
        }
    },
    computed: {
        
        warning() {
            return this.getQuestion();
        },
        
        cssClass() {
            let warning = this.getQuestion();
            return [warning.question_type];
        }
        
    },
    methods: {
        getQuestion() {

            return this.getQuestionByKey('id', this.questionId);

        },
        
        getQuestionByKey(key, value, multiple, type) {


            const _this = this;

            if(_.isUndefined(multiple)) {
                multiple = false;
            }

            if(_.isUndefined(type)) {
                type = 'questions';
            }


            let question = false,
                    questions = [];

            for (let sIndex in _this.$store.state.assessment.sections) {

                for (let qIndex in _this.$store.state.assessment.sections[sIndex][type]) {

                    let q = _this.$store.state.assessment.sections[sIndex][type][qIndex];

                    if (q.hasOwnProperty(key)) {

                        if (q[key] == value) {
                            question = q;
                            questions.push(q);
                        }

                    }

                }

            }

            return multiple ? questions : question;

        },
    }
}
</script>

<style lang="less" scoped>

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .warningWrap {
        
        background: transparent;
        border: none;
        border-radius: 0;
        margin: 0;
        padding: 27px 0;
        border-bottom: 1px solid @border_color;
        
        .warningBox {
            margin: 0;
            padding: 24px !important;
            border-radius: 24px !important;
            .rems(17) !important;
            line-height: 1.2em !important;
            font-weight: 600 !important;
            
        }
        
    }
    
    

</style>
