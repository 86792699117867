<template>

    <input type="email"
           :name="questionName"
           :id="'id_' + question.id"
           v-model="question.answer"
           :placeholder="placeholder"
           @blur="updateQuestion(question)"
           @input="updateQuestion(question, true)"
           autocomplete="off"
           class="assessmentEmailField"
    />
    
</template>

<script>

import _ from 'lodash';
import { props, computed, methods } from "../mixins/fields";

export default {
    name: "numberField",
    mixins: [props, computed, methods],
    props: {
        placeholder: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        allowInputEvent: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    methods: {

        updateQuestion: _.debounce(function(question, inputEvent) {

            if(typeof inputEvent == 'undefined' || this.allowInputEvent) {
                question = this.validate(question);
                this.$emit('update-question', question);
            }

        }, 200),
        
        validate(question) {

            let canProceed = true;
            
            if (question.answer.length) {
                let re = /\S+@\S+\.\S+/;
                canProceed = re.test(question.answer);
            }else{
                if(question.is_required) {
                    canProceed = false;
                }
            }
            
            question.can_proceed = canProceed;
            
            return question;
            
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .assessmentEmailField {
        border-radius: 59px !important;
    }
    

</style>
