var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.section.visible,
          expression: "section.visible",
        },
      ],
      staticClass: "assessmentSection",
    },
    [
      _vm.showSectionHeader
        ? _c("header", { staticClass: "sectionHeader" }, [
            _c("h2", {
              staticClass: "sectionTitle",
              domProps: { innerHTML: _vm._s(_vm.section.title) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.section.product_selection
        ? [
            [
              _vm.productGroups[0]
                ? _c("product-listings", {
                    attrs: {
                      selectionEnabled: true,
                      products: _vm.productGroups[0],
                    },
                    on: { "option-changed": _vm.validate },
                  })
                : _vm._e(),
            ],
          ]
        : _vm.section.product_confirmation
        ? [
            _vm._l(_vm.selectedProductGroups, function (products) {
              return [
                _c("product-listings", {
                  attrs: { selectionEnabled: false, products: products },
                  on: { "option-changed": _vm.validate },
                }),
              ]
            }),
          ]
        : [
            _vm._l(_vm.section.questions, function (question, index) {
              return [
                _vm.isQuestion(question)
                  ? _c("question-wrap", {
                      key: "qwrap" + question.id,
                      ref: "questionWrap",
                      refInFor: true,
                      attrs: {
                        "question-id": question.id,
                        "question-number": question.number,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.custom_error?.length
                  ? _c("warning-wrap", {
                      attrs: {
                        "question-id": 0,
                        "custom-error": question.custom_error,
                      },
                    })
                  : _vm.isWarning(question)
                  ? _c("warning-wrap", {
                      key: "wwrap" + question.id,
                      attrs: { "question-id": question.id },
                    })
                  : _vm.isHTML(question)
                  ? _c("markup-wrap", {
                      key: "mwrap" + question.id,
                      attrs: { "question-id": question.id },
                    })
                  : _vm._e(),
              ]
            }),
          ],
      _vm._v(" "),
      _c("footer", { staticClass: "sectionFooter" }, [
        !_vm.section.product_selection
          ? _c("button", {
              staticClass: "button",
              attrs: { type: "button", disabled: _vm.proceedButtonDisabled },
              domProps: { innerHTML: _vm._s(_vm.proceedButtonText) },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.updateSection()
                },
              },
            })
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }