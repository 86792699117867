<template>

    <div class="fileUpload">


        <div class="imagePreview" v-if="showPreview">

            <a
                    href="#"
                    class="removeButton"
                    @click.prevent="removeImage()"
            >
                <font-awesome-icon :icon="['far', 'circle-xmark']" />
            </a>

            <div class="imageWrap">
                <img :src="question.answer_file" alt="Preview Image">
            </div> <!-- .imageWrap -->

        </div> <!-- .imagePreview -->
        
        
        <div class="uploadField">

            <label 
                    :for="'id_' + question.id"
            >
                Upload Image <font-awesome-icon :icon="['far', 'upload']" />
            </label>

            <input :type="fieldType"
                   :name="questionName"
                   :id="'id_' + question.id"
                   v-model="question.answer"
                   accept="image/*"
                   @input="imagePreview($event)"
                   @change="updateQuestion(question)"
            >

            <input 
                    type="hidden" 
                    name="bypass_validation[]" 
                    :value="question.file_already_set ? question.id : ''"
            >

        </div> <!-- .uploadField -->
        

    </div> <!-- .fileUpload -->
    
</template>

<script>

import { props, computed, methods } from "../mixins/fields";

export default {
    name: "imageUpload",
    mixins: [props, computed, methods],
    data() {
        return {
            filesize: 0
        }
    },
    computed: {
        
        showPreview() {
            return this.question.answer_file.length > 0 && !this.question.file_error.length
        },
        
        fieldType() {
            return this.question.file_already_set ? 'input' : 'file'
        }
        
    },
    methods: {
        
        updateQuestion: _.debounce(function(question, inputEvent) {

            if(typeof inputEvent == 'undefined' || this.allowInputEvent) {
                question = this.validate(question);
                this.$emit('update-question', question);
            }

        }, 200),

        validate(question) {

            let canProceed = true;

            if (!question.answer_file.length && question.is_required) {
                canProceed = false;
            }

            if(question.answer_file.length && this.filesize > 2097152) {
                this.question.file_error = 'Image file is too large. To continue, please upload an image that\'s less that 2mb in size';
                canProceed = false;
            }else {
                this.question.file_error = '';
            }
            
            if(question.file_error.length) {
                canProceed = false;
            }

            question.can_proceed = canProceed;

            return question;

        },

        
        imagePreview: function (e, questionID) {

            const vm = this,
                    file = e.target.files[0],
                    file_path = URL.createObjectURL(file);
            
            this.filesize = file.size;

            if(typeof file_path != 'undefined') {
                if(file_path.length) {
                    this.question.answer_file = file_path;
                    this.question.file_error = '';
                    return true;
                }
            }

            this.question.answer_file = '';
            this.question.file_error = '';
            this.question.answer = '';
            return false;

        },

        removeImage: function (questionID) {

            var vm = this;
            this.question.answer_file = '';
            this.question.file_error = '';
            this.question.answer = '';
            
            this.updateQuestion(this.question);

        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .fileUpload {
        
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 200px;
        
        @media @mobile {
            max-width: 100%;
        }

        .imageVaidationMessage {
            .rems(12);
            line-height: 16px;
            color: @alert_color;
            font-weight: 600;
            padding-top: 8px;
        }

        .imagePreview {

            position: relative;
            width: 200px;
            height: 200px;
            border-radius: 10px;
            border: 1px solid @primary_color;
            overflow: hidden;
            margin: 0 0 16px;
            
            @media @mobile {
                margin: 0 auto 15px auto;
            }

            .removeButton {

                .rems(20);
                line-height: 20px;
                color: @alert_color;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                z-index: 4;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                text-decoration: none;
                transition: color 200ms linear;
                padding: 8px 8px 0 0;

                i {
                    cursor: pointer;
                }

                &:hover{
                    color: darken(@alert_color, 24%);
                    transition: color 200ms linear;
                }

                &:active{
                    color: darken(@alert_color, 16%);
                    transition: color 200ms linear;
                }
            }

            .imageWrap {
                position: relative;
                z-index: 1;
                border-radius: 10px;
                overflow: hidden;
                width: 100%;
                height: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .fileName {
                display: none;
            }


        }

        label {
            padding: 10px 24px !important;
            background: #fff;
            border: 1px solid @primary_color;
            color: @dark_primary;
            .rems(14) !important;
            line-height: 1.2em !important;
            font-weight: 700;
            text-align: center;
            float: none;
            clear: both;
            backface-visibility: hidden;
            border-radius: 4px;
            width: 100%;
            min-width: 57px;
            transition: all 200ms linear;
            display: block;
            cursor: pointer;
            margin: 0;

            i {
                display: inline-block;
                margin-left: 8px;
            }

            .errorWrap & {
                border-color: @alert_color !important;
            }
            
            &:hover, &:active, &:focus {
                background: @primary_color;
                border-color: @primary_color;
                color: #fff;

                .errorWrap & {
                    background: fade(@alert_color, 8%) !important;
                }
            }
            

            &:before, &:after {
                display: none !important;
            }

        }

        input {
            position: absolute !important;
            left: -9999px !important;
        }

        .fileName {
            display: block;
            clear: both;
            font-weight: 700;
            .rems(16);
            line-height: 24px;
            padding: 16px 0;

            span {
                display: block;
            }
        }
    }
    

</style>
