<template>

    <div class="stockWrap">

        <span class="bubbleText" v-if="!hasStock">Out of Stock</span>
        <span class="bubbleText" v-else>In Stock</span>
        
    </div> <!-- .stockWrap -->

</template>

<script>
export default {
    name: "stock-badge",
    props: {
        hasStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        small: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    computed: {
        
        cssClasses() {
            let classes = [];

            if(this.hasStock) {
                classes.push('inStock');
            }else{
                classes.push('noStock');
            }
           
            if(this.small) {
                classes.push('smallBadge');
            }
            
            return classes;
        }
        
    }
}
</script>

<style lang="less" scoped>

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .stockWrap {
        display: flex;
        align-items: center;
        color: @dark_primary;
        
        span {
            .rems(13);
            font-weight: 500;
            line-height: 23.958px;
            color: inherit;
        }
    }
    
    
</style>
