import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';


Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {
            surgery: {
                practice_name: '',
                address_1: '',
                address_2: '',
                city: '',
                postcode: '',
                phone: '',
                id: ''
            },
            healthProfile: {
                medications: '',
                allergies: '',
                conditions: ''
            }
        },
        assessment: {
            type: 'form',
            category_id: false,
            product_id: false,
            individual_product: {
                id: false,
                size: 0,
                quantity: 1
            },
            sections: [],
            products: [],
            selected_products: {},
            recommendations_template: {} //used to copy the recommended products sections and generate multiple versions of this section
        },
        medicalConditionList: [],
        medicationsList: [],
        createdAssessments: [],
        minibasket: {
            enabled: true,
            productCardsQuickAdd: true
        }
    },
    mutations: {
        
        SET_ASSESSMENT_INDIVIDUAL_PRODUCT: (state, payload) => {
            state.assessment.individual_product = payload;
        },  

        SET_ASSESSMENT_PRODUCTS: (state, payload) => {

            let cleanPayload = {};
            
            for(const userID in payload) {
                
                let products = [];
                
                for(const productKey in payload[userID]) {
                    
                    let product = payload[userID][productKey];

                    if(_.isUndefined(product.selected)) {
                        product.selected = false;
                    }

                    if(!_.isUndefined(product.sizes)) {
                        let sizes = product.sizes.map(size => {

                            if(_.isUndefined(size.selected)) {
                                size.selected = false;
                            }
                            return size;
                        });

                        product.sizes = sizes;
                    }

                    products.push(product);
                    
                }

                cleanPayload[userID] = products;
                
            }
            
            
            state.assessment.products = cleanPayload;
        },
        SET_CREATED_ASSESSMENTS: (state, payload) => {
            state.createdAssessments = payload;
        },
        SET_ASSESSMENT_SELECTED_PRODUCTS: (state, payload) => {

            state.assessment.selected_products[payload.productProp] = [];
            
            if (payload.products?.length) {

                const selected = payload.products.filter(product => {

                    let selectedSizes = [];

                    if (product.sizes?.length) {

                        selectedSizes = product.sizes.filter(size => {
                            return size.selected;
                        });

                    }

                    return selectedSizes.length > 0 || product.selected;

                });

                let selectedObj = {};
                
                selected.forEach((val, i) => {
                    selectedObj[i] = val;
                });

                state.assessment.selected_products[payload.productProp] = {...state.assessment.selected_products[payload.productProp], ...selectedObj};
              

            }

            
        },
        SET_USER_SURGERY: (state, payload) => {
            state.user.surgery = { ...state.user.surgery, ...payload }
        },
        SET_MEDICAL_CONDITIONS_LIST: (state, payload) => {
            state.medicalConditionList = { ...state.medicalConditionList, ...payload }
        },
        SET_MEDICATIONS_LIST: (state, payload) => {
            state.medicationsList = { ...state.medicationsList, ...payload }
        },
        SET_USER_HEALTH_PROFILE: (state, payload) => {
            state.user.healthProfile = { ...state.user.healthProfile, ...payload }
        },
        SET_ASSESSMENT_SECTIONS: (state, payload) => {
            state.assessment.sections = { ...state.assessment.sections, ...payload }
        },
        SET_ASSESSMENT_QUESTION: (state, question) => {
            
            let sections = { ...state.assessment.sections }
            
            for(let sIndex in sections) {
                
                for (let qIndex in sections[sIndex].questions) {

                    if (question.id == sections[sIndex].questions[qIndex].id) {
                        sections[sIndex].questions[qIndex] = {...sections[sIndex].questions[qIndex], ...question}
                    }

                }
                
            }
            
            state.assessment.sections = { ...state.assessment.sections, ...sections }
            
        },
        SET_ASSESSMENT_TYPE: (state, type) => {

            const allowed = ['form', 'category', 'product'];

            if(allowed.indexOf(type) > -1) {
                state.assessment.type = type;
            }else{
                state.assessment.type = 'form';
            }
        },
        SET_ASSESSMENT_PRODUCT_ID: (state, ID) => {

            state.assessment.product_id = ID;
        },
        SET_ASSESSMENT_CATEGORY_ID: (state, ID) => {

            state.assessment.category_id = ID;
        },
        SHOW_ASSESSMENT_SECTION: (state, sectionID) => {

            let sections = { ...state.assessment.sections }
            
            for(const sIndex in sections) {
                
                if(sections[sIndex].id == sectionID) {
                    sections[sIndex].visible = true;
                }else{
                    sections[sIndex].visible = false;
                }
                
            }

            state.assessment.sections = { ...state.assessment.sections, ...sections }

        },
    },
    actions: {

        setAssessmentIndividualProduct: (context, payload) => {

            context.commit('SET_ASSESSMENT_INDIVIDUAL_PRODUCT', payload);
            
        },
        setUserSurgery: (context, payload) => {
            context.commit('SET_USER_SURGERY', payload);
        },
        setMedicalConditionList: (context, payload) => {
            context.commit('SET_MEDICAL_CONDITIONS_LIST', payload);
        },
        setMedicationsList: (context, payload) => {
            context.commit('SET_MEDICATIONS_LIST', payload);
        },
        setUserHealthProfile: (context, payload) => {
            context.commit('SET_USER_HEALTH_PROFILE', payload);
        },
        setAssessmentSection : (context, payload) => {
            payload = _.sortBy(payload, 'order')
            context.commit('SET_ASSESSMENT_SECTIONS', payload);
        },
        updateAssessmentQuestion: (context, question) => {
            context.commit('SET_ASSESSMENT_QUESTION', question);
        },
        setAssessmentType: (context, type) => {
            context.commit('SET_ASSESSMENT_TYPE', type);
        },
        setAssessmentProductID: (context, payload) => {
            context.commit('SET_ASSESSMENT_PRODUCT_ID', payload);
        },
        setAssessmentCategoryID: (context, payload) => {
            context.commit('SET_ASSESSMENT_CATEGORY_ID', payload);
        },
        showAssessmentSection: (context, sectionID) => {
            context.commit('SHOW_ASSESSMENT_SECTION', sectionID);
        },
        setAssessmentSelectedProducts: (context, payload) => {
            context.commit('SET_ASSESSMENT_SELECTED_PRODUCTS', payload);
        },
        setAssessmentProducts: (context, payload) => {
            context.commit('SET_ASSESSMENT_PRODUCTS', payload);
        },
        setCreatedAssessments: (context, payload) => {
            context.commit('SET_CREATED_ASSESSMENTS', payload);
        },
    },
    getters: {

        getAssessmentSectionNextStepText: state => {

            let current = false,
                sectionNumber = '2';
            
            for(const prop in state.assessment.sections) {

                const section = state.assessment.sections[prop];
                
                if(current) {
                    sectionNumber = section.section_number;
                    current = false;
                }
                
                if(section.visible) {
                    current = true;
                }
                
            }
            
            
            return 'Next Step';
            
        },
        getAssessmentProducts: state => {
            return state.assessment.products;
        },
        getAssessmentIndividualProductID: state => {
            return state.assessment.product_id;
        },
        getAssessmentSelectedProducts: state => {
            return state.assessment.selected_products;
        },
        getUserSurgery: state => {
            return state.user.surgery;
        },
        getMedicalConditionList: state => {
            return state.medicalConditionList;
        },
        getMedicationsList: state => {
            return state.medicationsList;
        },
        getUserHealthProfile: state => {
            return state.user.healthProfile;
        },
        getAssessmentType: state => {
            return state.assessment.type;
        },
        getAssessmentSections: state => {
            return _.orderBy(state.assessment.sections, 'order');
        }
    }
});
