<template>

    <article class="productCard" :class="[large ? 'largeCard' : '', productBadge.text.length ? 'widthBadge' : '']">

        <div
                class="productBadge"
                v-if="productBadge.text.length"
                :class="[productBadge.class]"
                v-html="productBadge.text"
        ></div> <!-- .productBadge -->

        <header class="productHeader">

            <a :href="productUrl" class="productTitleLink">

                <h2
                        class="productTitle"
                        v-text="product.name"
                        v-if="product.name"
                ></h2>

                <h3
                        class="categoryTitle"
                        v-text="product.custom.product_subtitle"
                        v-if="product.custom?.product_subtitle?.length && showSubtitle"
                ></h3>

                <stock-badge
                        :has-stock="inStock"
                        :small="true"
                        v-if="showStock"
                ></stock-badge>

                <div
                        class="productContent"
                        v-if="productDescription"
                        v-html="productDescription"
                ></div> <!-- .productContent -->


            </a>

        </header>

        <section class="productImageSection">

            <div class="productCardImage" v-if="slideshow">

                <product-images
                        :images="product.images"
                        :lazy-load="true"
                        size="thumbs"
                        :slideshow="true"
                        :show-arrows="true"
                        :show-thumbs="false"
                        :allow-zoom="false"
                ></product-images>

            </div> <!-- .productCardImage -->

            <a :href="productUrl" class="productCardImage" v-else>

                <product-images
                        :images="product.images"
                        :lazy-load="true"
                        size="thumbs"
                        :slideshow="false"
                        :show-arrows="false"
                        :show-thumbs="false"
                        :allow-zoom="false"
                ></product-images>

            </a>

        </section>

        <footer class="productFooter">

            <div class="footerBottom">

                <div class="priceWrapper" v-if="fromPriceCalculated">

                    <span
                            class="fromPrefix"
                            v-if="product.sizes?.length && allowFromText"
                    >From</span>

                    <multi-currency-price :price="fromPriceCalculated"/>

                    <multi-currency-price
                            :price="pricePerTablet"
                            v-if="pricePerTablet > 0"
                            :postfix="' / ' + pillTYpe"
                    />

                </div> <!-- .priceWrap -->

                <add-to-basket
                        :ajax-add-to-basket="$store.state.minibasket.productCardsQuickAdd"
                        :basket-button-data="basketButtonData"
                        :browse-page="true"
                        :product="product"
                        :default-size-selected-id="defaultSelectedSizeID"
                        @sizechanged="sizeUpdated"
                ></add-to-basket>

            </div> <!-- .footerBottom -->


        </footer>

    </article>

</template>

<script>

import _ from 'lodash';
import addToBasket from './partials/add_to_basket';
import MtcNumberInput from '../../mtc_number_input';

export default {
    name: "product-card",
    components: {
        addToBasket,
        MtcNumberInput
    },
    props: {
        large: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        classes: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        //If true, price will be hidden and button will become a link
        minimal: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        fromPrice: {
            type: [Number, Boolean],
            default (rawProps) {
                return false;
            }
        },
        inStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        showStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        productUrl: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        productImage: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        lazyLoad: {
            type: [Boolean],
            default (rawProps) {
                return true;
            }
        },
        slideshow: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketButtonData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    label: false,
                    url: false
                };
            }
        },
        category: {
            type: [Object, Array, Boolean, String],
            default (rawProps) {
                return false;
            }
        },
        showDescription: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        showSubtitle: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },

    mounted() {
        const vm = this;
    },

    data() {
        return {
            showEffects: false,
            showMedType: false,
            maxDescriptionLength: 600,
            viewProductText: 'View product',
            fallbackImagePath: '/sites/default/images/placeholders/no-image-items-thumb.png',
            quantityAdjustVisible: false,
            interval: null,
            allowFromText: true,
            sizeSelected: false
        }
    },
    computed: {

        fromPriceCalculated() {
            
            if(!this.sizeSelected) {
                return this.getLowestPriceSize(true);
            }else{
                return this.sizeSelected.actual_price;
            }
        },

        pricePerTablet() {
            
            let size = this.getLowestPillPriceSize();


            if(this.sizeSelected) {
                size = this.sizeSelected;
            }

            let returnPrice = 0;

            if(size.hasOwnProperty('price_per_pill')) {
                returnPrice = parseFloat(size.price_per_pill);
            }

            return returnPrice;
        },

        pillTYpe() {
            if (
                typeof this.product.custom.pill_type !== 'undefined' &&
                this.product.custom.pill_type.length
            ) {
                return this.product.custom.pill_type
            }
            return 'Tablet';
        },

        productDescription() {


            let text = '';

            if(this.product?.custom?.description__product_card) {
                text = this.product.custom.description__product_card;
            }else if(this.product?.description) {
                text = this.product.description;

                if(text.length) {

                    let maxLength = this.maxDescriptionLength;

                    let append = '';

                    if(text.length > maxLength) {
                        append = '...';
                    }

                    text = text
                                    .replace(/(<([^>]+)>)/gi, "")
                                    .substring(0,maxLength)
                            + append;

                    return '<p>' + text + '</p>';

                }
            }


            if(text.length) {
                return text;
            }

            return false;
        },

        productImageSrc() {

            if(!this.productImage) {
                return this.fallbackImagePath;
            }

            return this.productImage;
        },

        defaultSelectedSizeID() {
            if (
                typeof this.product.recommended_size_id !== 'undefined' &&
                this.product.recommended_size_id > 0
            ) {
                return this.product.recommended_size_id;
            }
            return false;
        },

        //Can be used to added "recommended", "sale" etc. tags to product cards
        productBadge() {

            const badge = {
                class: '',
                text: ''
            }

            if(this.product?.custom?.product_badge?.length) {

                badge.text = this.product.custom.product_badge;

            }

            return badge;

        }

    },

    methods: {

        sizeUpdated(size) {
            this.allowFromText = false;
            this.sizeSelected = size;
        },

        getLowestPriceSize(returnPrice) {

            if (typeof this.product.sizes === "undefined" || this.product.sizes.length === 0) {
                return this.product.sale_price > 0 ?
                        this.product.sale_price :
                        this.product.price;
            }
            let minPrice = 0,
                    cheapestSize = false;

            this.product.sizes.forEach(size => {
                let sizePrice = size.sale_price > 0 ?
                        size.sale_price :
                        size.price;

                sizePrice = parseFloat(sizePrice);


                if (minPrice === 0) {
                    minPrice = sizePrice;
                    cheapestSize = size;
                }

                if (sizePrice < minPrice) {
                    minPrice = sizePrice;
                    cheapestSize = size;
                }
            });

            if(typeof returnPrice != 'undefined') {
                if(returnPrice) {
                    return minPrice;
                }
            }

            return cheapestSize;
        },

        getLowestPillPriceSize() {
            
            let cheapestPill = 0,
                    cheapestSize = false;

            this.product.sizes.forEach(size => {
                
                if(size.hasOwnProperty('price_per_pill')) {
                    
                    const perPill = parseFloat(size.price_per_pill);
                 
                    if(!isNaN(perPill)) {
                        
                        if(perPill > 0) {
                            
                            if(cheapestPill == 0) {
                                cheapestPill = perPill;
                            }
                            
                            if(perPill < cheapestPill) {
                                cheapestPill = perPill;
                                cheapestSize = size;
                            }
                        }
                        
                    }
                    
                }
                
            });
            

            return cheapestSize;
        },

        updateQuantitySelected(qty) {


            if(!_.isUndefined(qty)) {

                qty = parseInt(qty);

                if(!isNaN(qty)) {


                    this.product.quantity = qty;
                }
            }

            this.quantityAdjustVisible = false;



        }
    }
}

</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    @import '@site/../default/css/base/font.less';


    .productCard {
        background: #fff;
        display: flex;
        flex-direction: column;
        border-radius: 35px;
        text-align: left;
        width: 100%;
        height: 100%;
        position: relative;
        border: 1px solid #CACACA;
        padding: 25px 40px;

        @media all and (max-width: 1220px) {
            padding: 24px;
        }

        &.widthBadge {
            border-radius: 12px 35px 35px 35px;
        }

        .productBadge {
            position: absolute;
            top: 0;
            left: 0;
            padding: 10.5px 12px;
            background: #23887F;
            color: #fff;
            .rems(16);
            font-weight: 600;
            line-height: 1em;
            border-radius: 12px 0;
            z-index: 3;
        }


        .innerImage {
            order: 1;
            padding: 0;
        }

        .productHeader {
            order: 2;
            padding: 12px 0 0 0;
            text-align: inherit;
        }

        .priceWrapper {
            padding-top: 0;
            color: @primary_color;
            display: flex;
            flex-wrap: wrap;

            .fromPrefix {
                display: block;
                .rems(15);
                font-weight: 400;
                line-height: 16px;
                flex: 0 0 100%;
            }

            .multiPrice {

                .rems(23);
                font-weight: 700;
                line-height: normal;
                flex: 0 0 auto;

                & + .multiPrice {
                    padding-left: 14px;
                }
            }

        }

        .productFooter {
            order: 4;
            padding: 15px 0 0 0;
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            margin-top: auto;


            .footerBottom {
                padding: 0;
                margin-top: 0;
            }
        }

        .productTitleLink {
            color: @primary_color;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .productTitle {
                color: @primary_color;
                transition: color 200ms linear;
            }


        }


        a.productTitleLink {

            &:hover, &:active, &:focus-visible {
                .productTitle {
                    color: @primary_color;
                    transition: color 200ms linear;
                }
            }

        }

        .productTitle {

            margin: 0;
            .rems(24);
            font-weight: 700;
            line-height: normal;
            letter-spacing: normal;
            color: inherit;
            font-family: @primary_font;

        }

        .categoryTitle {
            .rems(16);
            line-height: 1.2em;
            font-weight: 600;
            margin: 0;
            font-family: @primary_font;
        }

        .productImageSection {

            max-width: 100%;

            .productImage {

                &, &.largeImages {

                    padding: 0;

                    .mainImage {
                        border: none;
                        border-radius: 0;
                        overflow: hidden;
                        background: #fff;
                        aspect-ratio: 1;
                        width: auto;
                        max-height: 200px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }

                }


            }

        }

        .productContent {
            padding: 0;
            .rems(16);
            font-weight: 400;
            line-height: 24px;
            color: #505D68;
            margin: 0;


            p {
                color: inherit;
                margin: 0 0 20px;

                &:last-child {
                    margin: 0;
                }
            }

            ul, ol {
                .iconList();
                font-weight: 500;
                margin-bottom: 20px !important;
            }
        }

        .productCta {
            padding: 15px 15px 8px 15px;
            margin-top: auto;

            .button {
                margin: 15px 0 0;
                width: 100%;
            }

        }

        .basketActions {

            padding-top: 17px;

            .buttonGrid {

                display: flex;
                flex-direction: row;
                gap: 8px;
                width: 100%;

                .button {
                    .rems(18);
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 0.54px;
                    width: 100%;
                    margin: 0;
                    padding-left: 8px;
                    padding-right: 8px;

                    &.buttonOutlined {
                        &:hover {
                            background: transparent;
                            color: @primary_color;
                        }
                    }
                }

                .buttonItem {

                    flex: 1 1 100%;

                    & + .buttonItem {
                        flex: 0 0 50%;
                    }
                }
            }

            & > .addToBagButton {

            }

            .basketForm {
                flex: 0 0 100%;
            }

            .basketButtonRow {

                .rowInput {
                    flex: 0 0 80px;
                    padding-right: 15px;

                    .mNumberInput input {
                        min-height: 41px !important;
                        height: 41px !important;
                    }
                }

                .rowButton {

                    .addToBagButton {
                        .rems(17);
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
        }

        .productSizes {

            & > label {
                display: none;
            }

            .chosen-container {

                max-width: 100%;


            }

        }

        .button {
            &.noMargin {
                margin: 0;
            }
        }


    }


</style>
