<template>

    <section class="themeToggleWrapper">

        <h6>
            <span>THEME TOGGLE</span>
            <span @click="hidePopup" class="closeButton">&#10006;</span>
        </h6>

        <ul>
            <li v-for="temp_theme in themes">
                <input
                    type="radio"
                    v-model="themeName"
                    :id="'theme_' + temp_theme"
                    :value="temp_theme"
                    @change="changeTheme"
                />
                <label
                    v-text="temp_theme"
                    :for="'theme_' + temp_theme"
                ></label>
            </li>
        </ul>

        <div class="homeLink">
            <a href="/" title="Return to homepage">
                Go to homepage
            </a>
        </div> <!-- .homeLink -->

    </section>

</template>


<script>

export default {

    props: [
        'themes',
    ],

    data() {
        return {
            themeName: 'pharmacy',
        };
    },


    created: function() {
        const cookie_lines = document.cookie.split(';');
        cookie_lines.forEach((el) => {
            const cookie_data = el.trim().split('=')
            if (cookie_data[0] == 'selected_theme') {
                this.themeName = cookie_data[1];
            }
        });

        document.addEventListener('click', function(e) {
            if (e.target.closest('.jsThemeToggle')) {
                document.querySelector('.themeToggleWrapper').style.display = 'block'
                e.preventDefault();
            } else {
                document.querySelector('.themeToggleWrapper').style.display = 'none'
            }
        });
    },


    methods: {

        changeTheme() {
            document.cookie = 'selected_theme=' + this.themeName + "; path=/"
            location.reload();
        },

        hidePopup() {
            document.querySelector('.themeToggleWrapper').style.display = 'none'
        },

    },

}

</script>

<style lang="less" scoped>

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .themeToggleWrapper {
        position: fixed;
        left: 5%;
        top: 5%;
        right: 5%;
        z-index: 99999;
        background-color: #eeeeee;
        padding: 1em;
        box-shadow: 0 0 14px #66666699;
        border-radius: 4px;
        display: none;
        max-width: 320px;
    
        input[type=radio] {
            appearance: auto;
            margin-right: 0.5em;
        }
    
        ul {
            display: block;
            width: 100%;
            float: none;
            clear: both;
            margin: 0 0 12px;
    
            li {
                .rems(20);
                line-height: 1.2em;
                margin: 0 0 8px;
                
                label {
                    font-weight: bold;
                    color: @text_color;
                    transition: color 200ms linear;
                    
                    &:hover, &:active {
                        color: @highlight_color;
                        transition: color 200ms linear;
                    }
                }
            }
    
        }
    
        h6 {
            font-size: 1em;
        }
    
        .closeButton {
            cursor: pointer;
            padding: 0 0.3em;
            float: right;

            color: @text_color;
            transition: color 200ms linear;

            &:hover, &:active {
                color: @highlight_color;
                transition: color 200ms linear;
            }
        }

        .homeLink {
            display: block;
            float: none;
            width: 100%;
            padding: 12px 12px 0;
            text-align: center;
            border-top: 1px solid rgba(0,0,0,0.16);
            font-weight: 600;
        }
    }

</style>


