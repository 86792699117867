<template>
    
    <div
            class="trustpilotCardWrapper"
            v-if="trustpilotBusinessID"
            :class="[trustpilotReady ? 'trustpilotReady' : '']"
    >

        <a :href="trustpilotLink" target="_blank">

            <div
                    class="trustpilot-widget"
                    data-locale="en-GB"
                    :data-template-id="trustpilotTemplateID"
                    :data-businessunit-id="trustpilotBusinessID"
                    :data-style-height="height"
                    :data-style-width="width"
                    data-theme="light"
                    :data-sku="Skus"
                    data-font-family="Quicksand"
                    :data-text-color="trustpilotTextColor"
                    :data-star-color="trustpilotStarColor"
                    data-no-reviews="show"
                    data-scroll-to-list="false"
                    data-style-alignment="left"
                    ref="trustbox"
            >
                <span
                        :href="trustpilotLink"
                        target="_blank"
                        rel="noopener"
                >Trustpilot</span>
            </div>

            <span class="visuallyHidden">Trustpilot</span>
        </a>

    </div> <!-- .trustpilotCardWrapper -->
    

</template>

<script>
import _ from "lodash";

export default {
    name: "trustpilot",
    props: {
        Skus: {
            type: [Boolean, String],
            default (rawProps) {
                return '';
            }
        },
        widgetType: {
            type: [String, Boolean],
            default (rawProps) {
                return 'badge';
            }
        }
    },
    data() {
        return {
            trustpilotReady: true,
            trustpilotBusinessID: false,
            trustpilotTemplateID: '',
            trustpilotLink: '',
            trustpilotStarColor: '',
            trustpilotTextColor: '',
            width: '190px',
            height: '24px',
            interval: null
        }
    },
    mounted() {
        
        const vm = this;
        
        vm.interval = setInterval(() => {

            if (!_.isUndefined(window.Trustpilot)) {
                clearInterval(vm.interval);
                let elem = vm.$refs.trustbox;
             
                if(elem) {
                    window.Trustpilot.loadFromElement(elem);
                    vm.trustpilotReady = true;
                }
                vm.trustpilotReady = true;
            }

        }, 1000);
    },
    created() {
        
        if(!_.isUndefined(window.globalConfig)) {
            this.trustpilotBusinessID = this.getConfigOrFalse('trustpilotBusinessID');
            this.trustpilotTemplateID = this.getConfigOrFalse('trustpilotTemplateID');
            this.trustpilotLink = this.getConfigOrFalse('trustpilotLink');
            this.trustpilotStarColor = this.getConfigOrFalse('trustpilotStarColor');
            this.trustpilotTextColor = this.getConfigOrFalse('trustpilotTextColor');
            
            if(this.widgetType == 'reviews') {
                this.trustpilotTemplateID = '5717796816f630043868e2e8';
                this.width = '100%';
                this.height = '700px';
            }else if(this.widgetType == 'product-mini') {
                this.trustpilotTemplateID = '54d39695764ea907c0f34825';
                this.width = '190px';
                this.height = '24px';
            }else if(this.widgetType == 'testimonials') {

                this.trustpilotTemplateID = '53aa8912dec7e10d38f59f36';
                this.width = '100%';
                this.height = '140px';
            }
            
        }
    },
    computed: {
        
        
    },
    methods: {

        getConfigOrFalse(key) {

            if(!_.isUndefined(window.globalConfig)) {

                if(!_.isUndefined(window.globalConfig[key])) {
                    return window.globalConfig[key];
                }

            }

            return false;

        }
        
    }
}
</script>

<style lang="less">

    .trustpilotCardWrapper {
        
    }
    
</style>
