export const computed = {

    computed: {
        
        //Returns the question name for forms fields :name="questionName"
        questionName() {

            if(typeof this.question != 'undefined') {
                if(typeof this.question.id != 'undefined') {
                    return 'questions[' + this.question.id + ']';
                }
            }
            
            return 'questions[noid]';
        }
    }
    
};

export const methods = {

    methods: {

        //Validate a question via event
        externallyValidate() {
            
            if(typeof this.question != 'undefined') {
                let question = this.question;
                question = this.validate(question);
                this.$emit('update-question', question);
            }
            
        }
    }

};

export const props = {
    
    props: {
        question: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
    }
    
};
