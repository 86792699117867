Vue.component('productOptions', {
    props: [
        'item_strengths',
        'selected_item',
        'assessment_complete',
        'item_warning',
        'product_overlay_enabled'
    ],
    data: function () {
        return {
            strengths: [],
            selectedStrengthIndex: 0,
            selectedSizeIndex: 0,
            selectedStrength: false,
            selectedSize: false,
            price: 0,
            salePrice: 0,
            savingPrice: 0,
            useSelects: true,
            item: false,
            saleActive: false,
            stock: 0,
            loading: true,
            mobileMenuActive: false,
            mobileMenuTimer: null,
            mobileView: false,
            has_assessment_form: false,

            displayPriceString: '£0.00',
            displaySalePriceString: '£0.00',
            displaySavingPriceString: '£0.00',

            mobileOverlayEnabled: false //true = Will show a mobile overlay to select options //false will show the options directly on the page at mobile
        };
    },
    created: function() {

        var vm = this;

        if(typeof vm.product_overlay_enabled != 'undefined') {
            vm.mobileOverlayEnabled = vm.product_overlay_enabled;
        }

        if(typeof this.item_strengths) {
            this.strengths = this.item_strengths;
        }

        if(typeof this.selected_item != 'undefined') {

            this.item = this.selected_item;

            var price = parseFloat(this.item.price),
                salePrice = parseFloat(this.item.sale_price);
            

            //update stock
            this.stock = this.item.stock;

        }


        this.has_assessment_form = !this.assessment_complete;
        

        this.$nextTick(function() {
            EventBus.$emit('updatePriceData', vm.$data);
        });

    },

    mounted: function() {

        var vm = this;


        //Check if radio dropdowns require a button
        vm.radiosRequireButton();

        //Select boxes for mobile
        vm.triggerSelects();

        //Check for mobile view
        vm.isMobileView();


        $(window).on('resize', function() {
            //Check if radio dropdowns require a button
            vm.radiosRequireButton();

            //Select boxes for mobile
            vm.triggerSelects();

            //Reset mobile menu
            vm.toggleMobileMenu(true);

            //Check for mobile view
            vm.isMobileView();

        });


        vm.$nextTick(function() {
            vm.loading = false;
            EventBus.$emit('priceDataReady', true);

        });




        //Init strengths and sizes
        vm.setSelectedStrength();


        /*
       * Sticky images
       */

        if( $('.productSticky').length ) {


            vm.$nextTick(function() {


                var navigationOffset = 146;


                if ($(window).width() > 980) {

                    $('.productSticky .itemImages').stick_in_parent({
                        parent: '.productSticky',
                        offset_top: 0 + navigationOffset
                    });

                }

                $(window).on('resize', function () {
                    if ($(window).width() <= 980) {
                        $('.productSticky .itemImages').trigger('sticky_kit:detach');
                    } else {
                        $('.productSticky .itemImages').trigger('sticky_kit:recalc');
                    }
                });
            });

        }



        vm.$nextTick(function() {
            //Overlay trigger
            $('.js_overlayTrigger').mtcOverlay();

        });

        if(vm.mobileOverlayEnabled) {
            $('body').addClass('mobileOverlayEnabled');
        }else{
            $('body').addClass('mobileOverlayDisabled');
        }


    },


    computed: {

        displayPrice: function() {

            var prefix = '';

            if(this.has_assessment_form) {
                prefix = '<span class="fromPrefix">From</span>';
            }

            var p = '';

            if(this.saleIsActive()) {
                p = prefix + ' £' + this.formatPrice(this.sale_price);

                if(this.price > this.salePrice) {
                    this.savingPrice = this.price - this.sale_price;
                    this.displaySavingPriceString = ' £' + this.formatPrice(this.savingPrice);
                }else{
                    this.savingPrice = 0;
                    this.displaySavingPriceString = ' £' + this.formatPrice(this.savingPrice);
                }
            }else{
                p = prefix + ' £' + this.formatPrice(this.price);

                this.savingPrice = 0;
                this.displaySavingPriceString = ' £' + this.formatPrice(this.savingPrice);

            }


            this.displayPriceString = p;

            return p;
        },

        displaySalePrice: function() {

            var p = '';

            if(this.saleIsActive()) {
                p = '£' + this.formatPrice(this.price);
            }else{
                p = '£0.00';
            }


            this.displaySalePriceString = p;

            return p;
        }
    },

    methods: {


        scrollToOptions: function() {

            if(this.redirectTo) {
                window.location = this.redirectTo;
                return;
            }

            if($('.itemOptions').length) {

                var barOffset = 0;

                if($('#itemSectionNav').length) {
                    if($('#itemSectionNav').is(':visible')) {
                        barOffset = $('#itemSectionNav').outerHeight() + 16;
                    }
                }


                var offset = $('.itemOptions').offset(),
                    offTop = -1;

                if(offset.hasOwnProperty('top')) {
                    if(typeof offset.top != 'undefined') {
                        offTop = offset.top;
                    }
                }
                if(offTop > -1) {
                    $('html, body').delay(200).animate({
                        scrollTop: offTop - barOffset
                    }, 1200);
                }
            }

        },

        formatPrice: function(price) {

            let displayPrice = '0.00';

            if (price) {
                displayPrice = parseFloat(price).toFixed(3).slice(0,-1);
            }

            return displayPrice;

        },

        displaySizePrice: function(size) {

            var prefix = '';

            if(this.has_assessment_form) {
                prefix = '<span class="fromPrefix">From</span>';
            }

            if(this.sizeSaleIsActive(size)) {
                return prefix + ' £' + this.formatPrice(size.sale_price);
            }else{
                return prefix + ' £' + this.formatPrice(size.price);
            }
        },

        displaySizeSalePrice: function(size) {

            if(this.sizeSaleIsActive(size)) {
                return '£' + this.formatPrice(size.price);
            }else{
                return '£0.00';
            }
        },

        displayStrengthPrice: function(index) {

            var strength = this.selectedStrength;

            if(typeof index != 'undefined') {
                strength = this.strengths[index];
            }

            var prefix = '';

            if(this.has_assessment_form) {
                prefix = '<span class="fromPrefix">From</span>';
            }



            if(typeof(strength.sizes[0]) != 'undefined') {


                var size = strength.sizes[0];

                if (this.strengthSaleIsActive(strength)) {
                    return prefix + ' £' + this.formatPrice(size.sale_price);
                } else {
                    return prefix + ' £' + this.formatPrice(size.price);
                }
            }else{
                return prefix + ' £' + this.formatPrice(this.item.price);
            }
        },

        displayStrengthSalePrice: function(index) {

            var strength = this.selectedStrength;

            if(typeof index != 'undefined') {
                if(typeof this.strengths[index] != 'undefined') {
                    strength = this.strengths[index];
                }
            }


            if(this.strengthSaleIsActive(strength) && typeof strength.sizes[0] != 'undefined') {

                var size = strength.sizes[0];

                return '£' + this.formatPrice(size.price);
            }else{
                return '£0.00';
            }
        },

        //Trigger mobile view
        isMobileView: function() {

            if(!this.mobileOverlayEnabled) {
                return false;
            }

            if($(window).width() <= 980) {
                this.mobileView = true;
            }else{
                this.mobileView = false;
            }

        },

        //Open the mobile menu
        toggleMobileMenu: function(close) {

            var vm = this;

            clearTimeout(this.mobileMenuTimer);

            if(vm.mobileMenuActive || close) {
                vm.mobileMenuActive = false;
                this.mobileMenuTimer = setTimeout(function() {
                    $('.siteInnerWrapper').css('z-index', 3);
                    $('#itemSectionNav').css('z-index', 9999999);
                },400);
            }else{
                vm.mobileMenuActive = true;
                this.mobileMenuTimer = setTimeout(function() {
                    $('.siteInnerWrapper').css('z-index', 999999);
                    $('#itemSectionNav').css('z-index', 4);
                },0);
            }

        },

        //Update price and sale price
        updatePrices: function() {

            var vm = this,
                size = vm.selectedSize,
                price = size.price,
                sale_price = size.sale_price;

            //update stock
            this.stock = size.stock;

            this.price = price;
            this.sale_price = sale_price;

            this.$nextTick(function() {
                EventBus.$emit('updatePriceData', vm.$data);
            });


        },
        //check if there is a sale on
        saleIsActive: function() {

            if(this.sale_price < this.price && this.sale_price > 0) {
                this.saleActive = true;
            }else{
                this.saleActive = false;
            }

            return this.saleActive;

        },

        sizeSaleIsActive: function(size) {

            var salePrice = parseFloat(size.sale_price),
                price = parseFloat(size.price);

            if(salePrice < price && salePrice > 0) {
                return true;
            }else{
                return false;
            }

            return false;

        },

        strengthSaleIsActive: function(inputStrength) {

            var strength = false;

            if(typeof inputStrength != 'undefined') {
                strength = inputStrength;
            }else{
                strength = this.selectedStrength;
            }

            var size = false;


            if(strength) {
                if (typeof strength.sizes[0] != 'undefined') {
                    size = strength.sizes[0];
                } else {
                    return false;
                }
            }else{
                return false;
            }



            var salePrice = parseFloat(size.sale_price),
                price = parseFloat(size.price);

            if(salePrice < price && salePrice > 0) {
                return true;
            }else{
                return false;
            }

            return false;

        },

        //Determine whether or not to use select boxes
        triggerSelects: function() {
           
            if(!this.mobileOverlayEnabled) {
                this.useSelects = true;
                return;
            }

            if($(window).width() <= 980) {
                this.useSelects = true;
            }else{
                this.useSelects = false;
            }
        },

        //Set the selected strength object
        setSelectedStrength: function() {

            var vm = this;

            if (vm.strengths) {
                vm.selectedStrength = vm.strengths[vm.selectedStrengthIndex];
            }

            vm.setSelectedQuantity();

        },

        setSelectedQuantity: function(index) {

            var vm = this;

            if(typeof index != 'undefined') {
                vm.selectedSizeIndex = index;
            }else{
                vm.selectedSizeIndex = 0;
            }

            if(typeof vm.selectedStrength != 'undefined') {
                if (vm.selectedStrength.hasOwnProperty('sizes')) {
                    vm.selectedSize = vm.selectedStrength.sizes[vm.selectedSizeIndex];
                }
            }

            vm.updatePrices();
        },

        //Check if radio options require controls
        radiosRequireButton: function() {

            var $targetRow = $('.itemOptions .selectionRows');

            if($targetRow.length) {

                $targetRow.each(function(i, val) {

                    if($(this).find('.buttonList').length) {

                        var targetHeight = $(this).find('.buttonList').outerHeight() - 8,
                            labelHeight = $(this).find('.buttonList li').eq(0).outerHeight(),
                            $btn = $(this).find('.reveal');

                        if (targetHeight > labelHeight) {
                            $btn.show();
                            $(this).find('.radioReveal').height($(this).find('.buttonList li').eq(0).outerHeight());
                            $(this).find('.radioReveal').removeClass('open');
                        } else {
                            $btn.hide();
                            $(this).find('.radioReveal').css('height', 'auto');
                        }
                    }

                });
            }

        },

    }
});

Vue.component('product-cta-box', {
    props: [
        'selected_item',
        'assessment_complete',
        'item_name'
    ],
    data: function () {
        return {

            item: false,
            saleActive: false,
            stock: 0,
            loading: true,
            has_assessment_form: false,

            savingPrice: 0,
            price: 0,
            salePrice: 0,

            displaySavingPriceString: '£0.00',
            displayPriceString: '£0.00',
            displaySalePriceString: '£0.00',

            navPricingReady: false
        };
    },
    created: function() {

        var vm = this;

        if(typeof this.selected_item != 'undefined') {

            this.item = this.selected_item;

            //update stock
            this.stock = this.item.stock;

        }

        this.has_assessment_form = !this.assessment_complete;

        EventBus.$on('updatePriceData', function(data) {
       
            vm.updateData(data);
            vm.loading = false;

            if(typeof Waypoint != 'undefined') {
                Waypoint.refreshAll();
            }
        });

        EventBus.$on('priceDataReady', function(isReady) {
            vm.navPricingReady = isReady;
        });
    },

    methods: {
        updateData: function(data) {

            var vm = this;

            Object.keys(this.$data).forEach(key => {
                if(key != 'navPricingReady') {
                    vm.$data[key] = null;
                }
            });

            Object.entries(data).forEach(entry => Vue.set(vm.$data, entry[0], entry[1]));

        },

        scrollToOptions: function() {

            $(document).ready(() => {
                if ($('.itemOptions').length) {

                    var offset = $('.itemOptions').offset(),
                        offTop = -1;

                    if (offset.hasOwnProperty('top')) {
                        if (typeof offset.top != 'undefined') {
                            offTop = offset.top;
                        }
                    }
                    if (offTop > -1) {
                        $('html, body').delay(200).animate({
                            scrollTop: offTop
                        }, 1200);
                    }
                }
            });

        }
    }
});


$(document).ready(function () {

    'use strict';





    /*
    * Image zoom
     */
    if ($('.enableZoom .zoom').length < 1 && $('.enableZoom').length) {
        $('.enableZoom').mtcZoom({
            hideMessageOnLaunch: true,
            paddingOffset: 0,
            message: '<i class="far fa-search-plus"></i>'
        });
    }

    /*
     * Item Back Button
     */

    $('.itemBackButton').on('click', function (e) {
        e.preventDefault();
        window.history.back();
    });

    /*
     * Description Accordion
     */

    if ($('.accordionWrapper').length) {

        // hide all accordion content
        $('.accordionContent').hide().removeClass('accordionContentOpen');

        // show open accordion content
        $('.accordionOpen .accordionContent').show().addClass('accordionContentOpen');

        $('.accordionHeading').on('click', function () {
            var $this = $(this);

            $('.accordionContentWrap').removeClass('accordionOpen');

            // remove class form all other
            $('.accordionHeading').removeClass('accordionHeadingOpen');

            // slide up any open accordion
            $this.parents('.accordionWrapper').children().find('.accordionContent').slideUp();

            // if this is hidden slide it down
            if ($this.next().is(':hidden')) {

                // add class to this
                $this.addClass('accordionHeadingOpen');

                // add class to this
                $this.next().slideDown(function () {
                    $this.parent('.accordionContentWrap').addClass('accordionOpen');
                });

            }

        }); // .on 'click' end

    } // if length end

    /*
    * Colour Linked Items Change
    */

    if ($('#colourLinkedItems').length) {

        $('body').on('change', '#colourLinkedItems', function () {
            var link = $(this).find(':selected').data('link');

            if (link !== undefined) {
                window.location.href = link;
            }
        });

    }

    /*
    * Item Strengths
    */
    var item_strengths = $('.itemStrengths');
    if (item_strengths.length) {
        item_strengths.on('change', function () {
            $('#selectedSize').val('');
            $('.sizeQuantities').hide();
            $('#sizeQuantities_' + $(this).val()).show();
            $('.itemSizes').val('').trigger('chosen:updated');
        });
        item_strengths.trigger('change');
        $('.itemSizes').on('change', function () {
            $('#selectedSize').val($(this).val());
        });

        $('.itemSizes').trigger('change').trigger("chosen:updated");
    }


    /*
    * Strengths & Sizes
     */

    $('.itemStrengths').on('change', function () {
        $('#sizeQuantities_' + $(this).val() + ' .itemSizes').trigger('change');
    });


    $('.itemSizes').on('change', function (select) {

        var productPrice = parseFloat($(this).children(':selected').data('price')),
            salePrice = parseFloat($(this).children(':selected').data('sale-price'));

        if(!isNaN(salePrice)) {
            if(salePrice > 0) {
                productPrice = salePrice;
            }
        }

        if(!isNaN(productPrice)) {
            $('.addToBasketWrap .itemPriceWrap').html('<span class="priceNow">&pound;' + this.formatPrice(productPrice) + '</span>');
        }

    });

    //Set initial value for radios
    $('.itemStrengths').eq(0).trigger('change');


    /*
    * Mobile doctor items trigger slidedown
    */
    $('.mobileDoctorItemTrigger .button').on('click', function(e) {

        e.preventDefault();

        $(this).closest('.buttonWrapper').addClass('optionsOpen');
        $('.innerDoctorWrap .innerForm').slideFadeToggle(function() {
            if( $('.innerDoctorWrap .buttonWrapper').hasClass('optionsOpen') ) {
                $('.innerDoctorWrap .innerForm').css('overflow', 'visible');
            }

            if($('.innerDoctorWrap .innerForm').is(':visible')) {
                $('body').addClass('productFlyoutOpen');
            }else{
                $('body').removeClass('productFlyoutOpen');
            }
        });
    });


    /*
    * Close mobile sidebar window
     */
    $('.innerDoctorWrap .close').on('click', function(e) {

        e.preventDefault();

        if( $('.innerDoctorWrap .buttonWrapper').hasClass('optionsOpen') ) {
            $('.optionsOpen').removeClass('optionsOpen');
            $('.innerDoctorWrap .innerForm').slideFadeToggle();
            $('body').removeClass('productFlyoutOpen');
        }
    });


    /*
    * Side Tabs
    */
    if($('.js_sideTabs').length) {

        $('.js_sideTabs').mtcTabs();

    }


    if($('.js_product_tabs').length) {

        $('.js_product_tabs').mtcTabs({

            onInit: function () {

                var mobileTitle = $('.js_product_tabs').eq(0).attr('data-mobile-title');

                if(mobileTitle.length > 0) {

                    $('.tabsNav').prepend('<h3 class="mobileTabsTitle">' + mobileTitle + '</h3>');
                    showHideMenus('.mobileTabsTitle', 800);

                }

            },

            afterChange: function(element_id) {

                if ($(window).width() <= 800) {

                    var $target = $('.tabContent[data-tab-id="' + element_id + '"]');

                    if ($target.length) {
                        $('html, body').animate({
                            scrollTop: $target.offset().top
                        }, 1000);
                    }

                }

            }

        });

    }


    /*
    * Sidebar Helper
    */
    productSidebar();


    

    if($('#itemSectionNav').length) {


        var $nav = $('#itemSectionNav'),
            $navItems = $nav.find('.sectionNav > ul > li'),
            $navLinks = $navItems.find('a');

        var stickyNavOffset = $nav.outerHeight(),
            wayPointOffset = stickyNavOffset;



        stickyProductNav();

        if($('.itemSectionSelect').length) {

            var $itemSectionSelect = $('.itemSectionSelect');

            $itemSectionSelect.on('change', function() {

                if( !$(this).hasClass('noScroll') ) {

                    var val = $(this).val();

                    if (val.length) {
                        var $link = $('.sectionNav a[href="' + val + '"]');

                        if ($link.length) {
                            $link.click();
                        }
                    }

                }

            });

        }
    }




}); // document ready



$(window).on('resize', debouncer(function (e) {

    'use strict';

    /*
    * Sidebar Helper
    */
    productSidebar();

    /*
    * Product page nav header
     */
    stickyProductNav();

    /*
    * Product Information
    */
    if($('.mobileTabsTitle').length) {
        showHideMenus('.mobileTabsTitle', 800);
    }

    /*
    * Category / product side tabs
    */
    if($('.sidebarTabs .sectionTitle').length) {
        showHideMenus('.sidebarTabs .sectionTitle', 700);
    }


})); // debounce


/*
  * Product Sidebar Sticky Menu Helper
 */
function productSidebar() {

    'use strict';

    if($('.innerDoctorWrap').length) {

        if ($(window).width() > window.breakpoints.product_small) {

            $('body').removeClass('mobileProductOptionsSet');

            if ($('.innerDoctorWrap').length && !$('.innerDoctorWrap .innerForm').is(':visible')) {
                $('.innerDoctorWrap .innerForm').show();
                $('.optionsOpen').removeClass('optionsOpen');
                $('.innerDoctorWrap').css('overflow', 'visible');

                //Reset wrapper padding
                $('.siteWrapper').css('padding-bottom', 0);
            }

        } else {

            if(!$('body').hasClass('mobileProductOptionsSet')) {
                $('body').addClass('mobileProductOptionsSet');
                $('.innerDoctorWrap .innerForm').hide();
                $('.optionsOpen').removeClass('optionsOpen');
            }

            //Adjust wrapper padding for sticky menu
            var wrapperOffset = $('.innerDoctorWrap').outerHeight();
            $('.siteWrapper').css('padding-bottom', wrapperOffset);
        }

    }
}


/*
* Sticky header nav
*/
var itemWrapWaypoints = false,
    navWaypoints = false;

function stickyProductNav() {

    if( $('#productTabs').length ) {

        var $target = $('#productTabs');

        var $nav = $('#itemSectionNav'),
            $navItems = $nav.find('.sectionNav > ul > li'),
            $navLinks = $navItems.find('a');

        var stickyNavOffset = $nav.outerHeight(),
            wayPointOffset = stickyNavOffset;

        if(itemWrapWaypoints) {
            itemWrapWaypoints[0].destroy();
            $nav.removeClass('isVisible');
        }

        if(navWaypoints) {
            navWaypoints[0].destroy();
        }

        var offset = 193;

        if($(window).width() <= window.breakpoints.flyout) {
            offset = 0 - ($('#header').outerHeight());
            $target = $('#header');
        }

        if($(window).width() <= window.breakpoints.mobile) {
            offset = 0 - ($('#header').outerHeight());
            $target = $('#header');
        }

        itemWrapWaypoints = $target.waypoint(function(direction) {

            if(direction == 'down') {
                $nav.addClass('isVisible');
            }else{
                $nav.removeClass('isVisible');
            }

        }, {
            offset: offset + 'px'
        });




        navWaypoints = $('.itemTabSection').waypoint(function(direction) {

            var $target = $('a[href="#' + this.element.id + '"]');

            if($target.length) {
                $navLinks.removeClass('active');
                $target.addClass('active');
            }

            if($('.itemSectionSelect').length) {

                var $itemSectionSelect = $('.itemSectionSelect');

                $itemSectionSelect.addClass('noScroll');

                $itemSectionSelect
                    .val('#' + this.element.id)
                    .trigger("chosen:updated");

                $itemSectionSelect.removeClass('noScroll');

            }


        }, {
            offset: wayPointOffset + 'px'
        });




    }

}

$(function() {
    $(".js-link").on("click", function(e) {
        e.preventDefault();
        window.location.href = $(this).data('url');
    });
});
