<template>

  <div
          id="assessmentContent"
          class="assessmentFormContent"
  >

      
<template v-if="!hideSidebarAndInfo">

    <div class="previousCompleted" v-if="previouslyCompleted">
        <div class="alert">
            <p>
                It appears you have already completed this assessment.
                Please check your answers and confirm the answers given
                still apply to you. If not, please update your answers.
            </p>
        </div> <!-- .alert alertBlue -->
    </div> <!-- .previousCompleted -->

    
    <div class="alertMessage" v-else>

        <div class="alert alertBlue">

            <p v-if="memberLoginLink">
                Already a member? <a :href="memberLoginLink">Click here</a> to login.
            </p>

        </div> <!-- .alert alertBlue -->

    </div> <!-- .alertMessage -->
    
</template>
      
      

      <form
              action=""
              enctype="multipart/form-data"
              method="post"
              class="formContentWrap"
              autocomplete="off"
              ref="mainForm"
              id="mainForm"
      >

          <template v-for="(section, sIndex) in sections">


              <form-section
                      :section="section"
                      :key="'section' + section.id"
                      :show-section-header="false"
                      :url-add-to-basket="urlAddToBasket"
                      @update-section="proceed"
                      v-if="section"
              />

          </template>

      </form> <!-- .formContentWrap -->

  </div>

</template>

<script>

import axios from 'axios';

import { mapGetters } from 'vuex';
import formSection from './form_section';

export default {
    name: "formContent",
    components: {
        formSection
    },
    computed: {
        ...mapGetters({
            sections: 'getAssessmentSections',
        }),
        memberLoginLink() {
            
            return '/members/login?redirect=' + window.location;
            
        }
    },
    props: {
        urlSubmitAssessment: {
            type: String,
            default: '/missing-url-submit-assessment',
        },
        urlGetAllowedProducts: {
            type: String,
            default: '/missing-url-get-allowed-products',
        },
        urlAddToBasket: {
            type: String,
            default: '/missing-url-add-to-basket',
        },
        categoryId: {
            type: Number,
            default: null,
        },
        productId: {
            type: Number,
            default: null,
        },
        previouslyCompleted: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadingStartTimeout: null,
            loadingEndTimeout: null,
            hideSidebarAndInfo: true
        }
    },
    methods: {

        proceed(section) {

            const _this = this;


            if(section.can_proceed && section.submit_assessment) {

                this.submitAssessment(section);

            }else{

                if(section.product_selection) {

                    this.updatedSelectedProducts(this.$store.state.assessment.products);

                    if(section.can_proceed) {
                        this.nextSection();
                    }

                } else if (section.can_proceed) {
                    this.nextSection();
                }

            }

        },

        submitAssessment(section) {

            section.loading = true;
            this.$parent.setLoading(true, 'Saving assessment');

            const _this = this;

            const form = this.$refs.mainForm;
            const form_data = new FormData(form);

            form_data.set('assessment_type', this.$store.state.assessment.type);
            form_data.set('category_id', this.categoryId);
            form_data.set('product_id', this.productId);

            axios.post(this.urlSubmitAssessment, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (response) {

                const data = response.data;

                const errors = data.errors,
                    questionErrors = data.question_errors;

                if (data.ok) {
                    
                    
                    _this.$store.dispatch('setCreatedAssessments', data.created_assessments);
                    
                    if(_this.$store.state.assessment.individual_product.id) {

                        _this.sendToBasket(section);
                        
                    }else {
                        axios.post(_this.urlGetAllowedProducts, {
                            assessment_ids: data.created_assessments,
                            category_id: _this.categoryId,
                            product_id: _this.productId,
                        })
                        .then(function (response) {
                            _this.nextSection();
                            _this.$store.dispatch('setAssessmentProducts', response.data);
                        });
                    }
                    
                    
                }

            })
            .finally(function () {
                section.loading = false;
            });

        },
        
        sendToBasket(section) {

            section.loading = true;

            const individualProduct = this.$store.state.assessment.individual_product;
            console.log(individualProduct);
            const form_data = new FormData();
            form_data.set('ajax', 'true');
            form_data.set('quantity', individualProduct.quantity);
            form_data.set('size', individualProduct.size);
            form_data.set('id', individualProduct.id);
            form_data.set('add-bag', true);

            axios.post(this.urlAddToBasket, form_data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function (response) {
                const data = response.data;
                section.loading = true;
                window.location = '/shop/checkout/basket.php';
            })
            .catch(function (error) {
                section.loading = true;
                window.location = '/shop/checkout/basket.php?error=failed_to_add_item';
            });

        },

        updatedSelectedProducts(products) {


            for(const userProp in products) {

                this.$store.dispatch('setAssessmentSelectedProducts', {
                    productProp: userProp,
                    products: products[userProp]
                });

            }


        },

        nextSection() {

            let sectionID = false,
                next = false;

            for(const prop in this.$store.state.assessment.sections) {

                let section = this.$store.state.assessment.sections[prop];

                if(next) {
                    sectionID = section.id;
                    next = false;
                    
                    if(section.product_selection || section.product_confirmation) {
                        this.hideSidebarAndInfo = true;
                    }
                }

                if(section.visible) {
                    next = true;
                }


            }

            if(this.hideSidebarAndInfo) {
                this.$parent.showFormInfo = false;
            }else{
                this.$parent.showFormInfo = true;
            }
            
            if(sectionID) {
                this.goToSection(sectionID);
            }

        },

        previousSection() {

            let sectionID = false,
                foundVisible = false;

            for(const prop in this.$store.state.assessment.sections) {

                let section = this.$store.state.assessment.sections[prop];

                if(section.visible) {
                    foundVisible = true;
                }

                if(!foundVisible) {
                    sectionID = section.id;
                }

            }

            if(sectionID) {
                this.goToSection(sectionID);
            }

        },

        goToSection(sectionID) {

            const _this = this;

            this.setLoading(true, 'Loading next step');

            clearTimeout(this.loadingStartTimeout);
            clearTimeout(this.loadingEndTimeout);

            this.loadingStartTimeout = setTimeout(() => {

                //assessmentContent
                document.getElementById('assessmentContent').scrollIntoView({
                    behavior: 'smooth'
                });

                this.$store.dispatch('showAssessmentSection', sectionID);


                this.loadingStartTimeout = setTimeout(() => {

                    this.setLoading(false);

                }, 1200);

            },800);


        },

        setLoading(loading, message) {
            this.$parent.setLoading(loading, message);
        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .assessmentFormContent {
        grid-area: formarea;
        background: @assessment_content_background;
        display: block;
        max-width: 870px;
        
        padding: 40px 0 80px;
        position: relative;

        @media all and (max-width: 1020px) {
            width: 100%;
            flex: 0 0 100%;
        }
        
        @media @mobile {
            //text-align: center;
        }
        
        & > .alert {
            margin: 0 0 50px;
        }

        .formContentWrap {
            width: 100%;
            margin: 0 auto;
            display: block;
        }

        .previousCompleted {
            .alert {
                background: fade(@secondary_color, 40%) !important;
                color: @text_color !important;
            }
        }

}



</style>
