<template>

    <div class="markupWrap">

        <div
                class="markupBox wysiwyg"
                v-html="markup.question_text"
        ></div> 
        
    </div>
    
</template>

<script>

export default {
    name: "markupWrap",
    components: {
       
    },
    props: {
        questionId: {
            type: [Number, String],
            default (rawProps) {
                return false;
            }
        },
    },
    computed: {

        markup() {
            return this.getQuestion();
        }
        
    },
    methods: {

        getQuestion() {

            return this.getQuestionByKey('id', this.questionId);

        },
        getQuestionByKey(key, value, multiple, type) {

            const _this = this;

            if(_.isUndefined(multiple)) {
                multiple = false;
            }

            if(_.isUndefined(type)) {
                type = 'questions';
            }


            let question = false,
                    questions = [];


            for (let sIndex in _this.$store.state.assessment.sections) {

                for (let qIndex in _this.$store.state.assessment.sections[sIndex][type]) {

                    let q = _this.$store.state.assessment.sections[sIndex][type][qIndex];

                    if (q.hasOwnProperty(key)) {

                        if (q[key] == value) {
                            question = q;
                            questions.push(q);
                        }

                    }

                }

            }

            return multiple ? questions : question;

        },
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .markupWrap {

        background: #fff;
        padding: 35px;
        border: 1.5px solid @border_color_dark;
        border-radius: 10px;
        margin: 0 0 25px;
        
    }
    
    .markupBox {
        
        p {
            margin: 0 0 16px;
            
            &:last-child {
                margin: 0;
            }
        }
        
        img {
            position: relative;
            object-fit: contain;
            width: 100%;
            height: 100%;
            max-width: 400px;
        }
        
    }
    
    

</style>
