<template>

    <div class="assessmentPageWrapper">

        <template v-if="setForm.form_title?.length">


            <div
                    class="titleBanner withImage leftAlign"
            >

                <div class="wrapper">

                    <div class="innerTitleBanner">

                        <div class="bannerContentWrap">

                            <div class="innerContentWrapper">

                                <page-title
                                        :title="setForm.form_title"
                                        content=""
                                        css-class="browseTitle"
                                ></page-title>

                            </div> <!-- .innerContentWrapper -->


                        </div> <!-- .bannerContent -->

                    </div> <!-- .innerTitleBanner -->


                </div> <!-- .wrapper -->


                <div class="imageBackground">

                    <picture class="imageWrap">

                        <template v-if="setForm?.form_image?.src?.length">

                            <img
                                :src="setForm?.form_image?.src"
                                :alt="setForm.form_image.alt"
                            />
                            
                        </template>
                        
                        <template v-else>

                            <img
                                src="/sites/default/images/placeholders/default-hero.png"
                                alt=""
                            />
                            
                        </template>


                    </picture> <!-- .imageWrap -->

                </div> <!-- .imageBackground -->



            </div> <!-- .titleBanner -->



        </template>

        <div class="wrapper">
            

            <main
                    class="assessmentForm"
                    :class="[loading ? 'isLoading' : '', showFormInfo ? '' : 'fullWidthContent']"
            >
    
    
                <form-content
                        :url-submit-assessment="urlSubmitAssessment"
                        :url-get-allowed-products="urlGetAllowedProducts"
                        :url-add-to-basket="urlAddToBasket"
                        :form-id="form.id"
                        :loading="loading"
                        :category-id="categoryId"
                        :product-id="productId"
                        :previously-completed="form.previously_complete"
                />
    
                <form-sidebar-info v-if="showFormInfo"/>
    
                <div class="loading">
    
                    <loading-spinner
                            :loading="loading"
                            :loading-text="loadingMessage"
                            :show-loading-text="true"
                    ></loading-spinner>
    
                </div> <!-- .loading -->
    
    
            </main>

        </div> <!-- .wrapper -->
        
    </div> <!-- .assessmentPageWrapper -->

    
</template>

<script>

import formSidebarInfo from './elements/form_sidebar_info';
import formContent from './elements/form_content';
import pageTitle from '../pages/page_title';

export default {
    
    name: 'assessmentForm',
    
    components: {
        formSidebarInfo,
        formContent,
        pageTitle
    },
    
    props: {
        setForm: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {};
            }
        },
        setSections: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        userSurgery: {
            type: [Object],
            default (rawProps) {
                return {
                    practice_name: '',
                    address_1: '',
                    address_2: '',
                    city: '',
                    postcode: '',
                    phone: '',
                    id: ''
                };
            }
        },
        userHealthProfile: {
            type: [Object, Array],
            default (rawProps) {
                return {
                    medications: '',
                    allergies: '',
                    conditions: ''
                };
            }
        },
        medicalConditionList: {
            type: [Object, Array],
            default (rawProps) {
                return [];
            }
        },
        medicationsList: {
            type: [Object, Array],
            default (rawProps) {
                return [];
            }
        },
        urlSubmitAssessment: {
            type: String,
            default: '/missing-url-submit-assessment',
        },
        urlGetAllowedProducts: {
            type: String,
            default: '/missing-url-get-allowed-products',
        },
        urlAddToBasket: {
            type: String,
            default: '/missing-url-add-to-basket',
        },
        categoryId: {
            type: Number,
            default: null,
        },
        productId: {
            type: Number,
            default: null,
        },
        productSizeId: {
            type: Number,
            default: null,
        },
        productQuantity: {
            type: Number,
            default: null,
        }
    },
    
    data() {
        return {
            loading: false,
            form: {},
            defaultLoadingMessage: 'Loading',
            loadingMessage: 'Loading',
            showFormInfo: false
        }
    },
    
    created() {
        
        this.form = this.setForm;
        
        //Store user data
        this.$store.dispatch('setUserSurgery', this.userSurgery);

        //Store health profile
        this.$store.dispatch('setUserHealthProfile', this.userHealthProfile);

        //Store list of medications for predictive inputs
        this.$store.dispatch('setMedicationsList', this.medicationsList);

        //Store list of conditions for predictive inputs
        this.$store.dispatch('setMedicalConditionList', this.medicalConditionList);

        //Store form type and ids
        this.$store.dispatch('setAssessmentType', this.form.form_type);
        this.$store.dispatch('setAssessmentProductID', this.productId);
        this.$store.dispatch('setAssessmentCategoryID', this.form.category_id);


        //Store individual product
        this.$store.dispatch('setAssessmentIndividualProduct', {
            id: this.productId,
            size: this.productSizeId,
            quantity: this.productQuantity
        });
        
        
        //Store sections
        this.$store.dispatch('setAssessmentSection', this.setSections);

        
        
    },
    
    mounted() {
        EventBus.$emit('assessmentStep', 1);
    },
 
    methods: {
        setLoading(loading, message) {
            
            if(_.isUndefined(message)) {
                message = this.defaultLoadingMessage;
            }
            
            this.loadingMessage = message;
            this.loading = loading;
        }
    }
    
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .assessmentPageWrapper {
        
        & > .wrapper {
            padding: 0 !important;
        }
        
    }
    
    .assessmentForm {
        display: grid;
        grid-gap: 0;
        grid-template-columns: 3fr 284px;
        grid-template-areas:  "formarea sidebarinfo";
        min-height: 80vh;
        color: @text_color;
        position: relative;
        width: 100%;
     

        @media all and (max-width: 1220px) {
            grid-template-columns: 3fr 284px;
        }

        @media all and (max-width: 1020px) {
            display: flex;
            flex-wrap: wrap;
        }
        
        .row {
            float: none;
            clear: both;
        }
        
        &.fullWidthContent {
            display: flex;
            justify-content: center;
            
            .assessmentFormContent {
                width: 100%;
                flex: 0 0 100%;
                max-width: 1092px;
                
            }
        }

        .loading {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9;
            background: rgba(255,255,255,0.5);
            backdrop-filter: blur(8px);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity 200ms linear, visibility 200ms linear;

            .loadingSpinner {
                position: sticky;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 10;
                display: none;
            }
            
            .loadingText {
                margin-top: -24px;
            }
        }

        
        &.isLoading {

            .loading {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transition: opacity 200ms linear, visibility 200ms linear;

                .loadingSpinner {
                    display: inline-block;
                }
            }

        }
    }
    

</style>
